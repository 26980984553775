import React, { useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import { DeliveryForm } from './DeliveryForm';
import IDelivery, { getPrefixedDeliveryId } from '../../../../models/IDelivery';
import { DELIVERY_STATUS_ENUM } from '../../../../api_enums/DELIVERY_STATUS_ENUM';

export function DeliveryEditDialog(props: { delivery: IDelivery }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const checkPermission = () => {
    return (
      (props.delivery?.status as DELIVERY_STATUS_ENUM) !==
        DELIVERY_STATUS_ENUM.ON_DELIVERY &&
      (props.delivery?.status as DELIVERY_STATUS_ENUM) !==
        DELIVERY_STATUS_ENUM.DELIVERED &&
      (props.delivery?.status as DELIVERY_STATUS_ENUM) !==
        DELIVERY_STATUS_ENUM.NOT_DELIVERED &&
      (props.delivery?.status as DELIVERY_STATUS_ENUM) !==
        DELIVERY_STATUS_ENUM.PARTIALLY_DELIVERED
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          t('DeliveryEditDialog.Title') +
          ' ' +
          getPrefixedDeliveryId(props.delivery.id)
        }
        size="90rem"
      >
        <DeliveryForm
          create={false}
          loading={false}
          delivery={props.delivery}
          onFinish={() => setOpened(false)}
        />
      </Modal>

      <ActionIcon
        disabled={!checkPermission()}
        color="orange"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
