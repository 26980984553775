import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// There are seperated translations.json files for each language in public/locales folder

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: true,
        supportedLngs:['de','en','by'],
        fallbackLng: 'de',
        detection:{
            order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches:['cookie'],
        },
        backend:{
            loadPath: '/locales/{{lng}}/translation.json'
        },
        react:{
            useSuspense: true,
        },
        interpolation: {
            escapeValue: false,
        },
        saveMissing: false
    });

export default i18n;
