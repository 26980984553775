import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, useMantineTheme } from '@mantine/core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function StatsVerticalBarChart(props: {
  data: {
    labels: string[];
    datasets: { label: string; data: number[]; backgroundColor: string }[];
  };
  title: string;
  stacked: boolean;
}) {
  const theme = useMantineTheme();
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: props.title,
        fontWeight: 'bold',
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: 22,
      },
    },
    layout: {},
    title: {
      display: true,
      text: props.title,
      fontWeight: 'bold',
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 22,
    },
    labels: {
      font: {
        weight: 'bold',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: props.stacked,
      },
      y: {
        grid: {
          display: false,
        },
        stacked: props.stacked,
      },
    },
  };

  return (
    <Card withBorder p="xl" radius="md" sx={{ height: '100%' }}>
      <Bar options={options} data={props.data} height={'auto'} />
    </Card>
  );
}

export default StatsVerticalBarChart;
