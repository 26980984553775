import {
  FilePlus,
  Icon,
  ReportMoney,
  TrafficLights,
  TruckDelivery,
  TruckReturn,
} from 'tabler-icons-react';

enum DELIVERY_STATUS_ENUM {
  PICKING = 'picking',
  LOADED = 'loaded',
  ON_DELIVERY = 'on_delivery',
  DELIVERED = 'delivered',
  PARTIALLY_LOST = 'partially_lost',
  COMPLETE_LOST = 'complete_lost',
  NOT_DELIVERED = 'not_delivered',
  PARTIALLY_DELIVERED = 'partially_delivered',
  PARTIALLY_LOADED = 'partially_loaded',
}

const DELIVERY_STATUS_ENUM_VALUES = new Map<
  string,
  { label: string; icon: Icon; color: string, priority: number }
>([
  [
    DELIVERY_STATUS_ENUM.PICKING,
    { label: 'Wird Verladen', icon: FilePlus, color: 'grey', priority: 0 },
  ],
  [
    DELIVERY_STATUS_ENUM.LOADED,
    { label: 'Verladen', icon: FilePlus, color: 'yellow', priority: 1 },
  ],
  [
    DELIVERY_STATUS_ENUM.ON_DELIVERY,
    { label: 'Unterwegs', icon: FilePlus, color: 'orange', priority: 3 },
  ],
  [
    DELIVERY_STATUS_ENUM.DELIVERED,
    { label: 'Ausgeliefert', icon: TrafficLights, color: 'green', priority: 5 },
  ],
  [
    DELIVERY_STATUS_ENUM.PARTIALLY_LOST,
    { label: 'Teilweise verloren', icon: ReportMoney, color: 'red', priority: 6},
  ],
  [
    DELIVERY_STATUS_ENUM.COMPLETE_LOST,
    { label: 'Komplett verloren', icon: TruckDelivery, color: 'red', priority: 7 },
  ],
  [
    DELIVERY_STATUS_ENUM.NOT_DELIVERED,
    { label: 'Nicht Geliefert', icon: TruckReturn, color: 'grey', priority: 8},
  ],
  [
    DELIVERY_STATUS_ENUM.PARTIALLY_DELIVERED,
    { label: 'Teilweise Ausgeliefert', icon: TrafficLights, color: 'grey', priority: 4 },
  ],
  [
    DELIVERY_STATUS_ENUM.PARTIALLY_LOADED,
    { label: 'Teilweise Verladen', icon: FilePlus, color: 'grey', priority: 2 },
  ],
]);

export { DELIVERY_STATUS_ENUM, DELIVERY_STATUS_ENUM_VALUES };
