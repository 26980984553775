import IVehicle from '../models/IVehicle';
import IGeoCoordinates from '../models/IGeoCoordinates';
import AbstractCRUDService from './AbstractCRUDService';

class VehicleService extends AbstractCRUDService<IVehicle> {
  // TODO fake calls
  async getGpsCoordinates(id: number): Promise<IGeoCoordinates> {
    return new Promise(function (resolve, reject) {
      if (Math.random() > 0.5) {
        resolve({ lat: 48.56782387736415, lng: 13.415571775771294 });
      } else {
        resolve({ lat: 48.57872772531815, lng: 13.431793774418997 });
      }
    });
  }

  async getAllGpsCoordinates(): Promise<
    { id: number; coords: IGeoCoordinates }[]
  > {
    return new Promise(function (resolve, reject) {
      resolve([
        { id: 1, coords: { lat: 48.56782387736415, lng: 13.415571775771294 } },
        { id: 2, coords: { lat: 48.5781404635075, lng: 13.464669349425142 } },
        { id: 3, coords: { lat: 48.594719084329014, lng: 13.39403069240798 } },
      ]);
    });
  }
}

export default new VehicleService('logistics/vehicle');
