import { Icon } from 'tabler-icons-react';
import { Text, Timeline } from '@mantine/core';
import React from 'react';
import IHistoryEntry from '../../models/IHistoryEntry';
import moment from 'moment/moment';
import { INQUIRY_STATUS_ENUM } from '../../api_enums/INQUIRY_STATUS_ENUM';

export function StatusHistory(props: {
  history: IHistoryEntry[];
  currentStatus: string;
  statusEnum: object;
  statusEnumValues: Map<string, { label: string; icon: Icon }>;
}) {
  // should have always at least one history element if created
  const statusIdx = Object.values(props.statusEnum).findIndex(
    (v) => v.toString() === props.currentStatus,
  );
  const statusDateMap = new Map<string, Date>();
  props.history
    .filter((e) => (e.change ? 'status' in e.change : false))
    .forEach((v) => statusDateMap.set(v.change.status.current as string, v.at));

  const excludedStatus = [
    INQUIRY_STATUS_ENUM.REJECTED,
    INQUIRY_STATUS_ENUM.CANCELLED,
  ].filter((item) => !statusDateMap.has(item));

  return (
    <Timeline active={statusIdx} bulletSize={24} lineWidth={2}>
      {Object.values(INQUIRY_STATUS_ENUM)
        .filter((value) => !excludedStatus.includes(value))
        .map((value) => {
          const enumValues = props.statusEnumValues.get(value);

          if (!enumValues) {
            console.error(
              'mismatch between ORDER_STATUS_ENUM and ORDER_STATUS_ENUM_VALUES',
            );
            return <></>; // should not happen
          }
          const Icon = enumValues.icon;
          return (
            <Timeline.Item
              color="green"
              title={enumValues.label}
              bullet={<Icon size={12} />}
            >
              {statusDateMap?.has(value) && (
                <Text size="xs" mt={4}>
                  {moment
                    .utc(statusDateMap?.get(value))
                    .local()
                    .format('DD.MM.YYYY - HH:mm:ss')}
                </Text>
              )}
            </Timeline.Item>
          );
        })}
    </Timeline>
  );
}
