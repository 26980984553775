import React, { useContext } from 'react';
import { ResourceContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import IResource from '../../models/IResource';

export function ResourceElement(props: { id: number }) {
  const ctx = useContext(ResourceContext) as AbstractContextType<IResource>;
  const loc = ctx.entities.filter((l) => l.id == props.id);
  return <>{loc && loc.length == 1 && loc[0].name} </>;
}
