import { Avatar, Badge } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import IUser from '../../models/IUser';

export function UserElement(props: { id: number }) {
  const ctx = useContext(UserContext) as AbstractContextType<IUser>;
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const u = ctx.entities.find((value) => value.id === props.id);
    if (u) {
      setUser(u);
    }
  }, [ctx.loading, ctx.entities]);

  return (
    <Badge
      sx={{ paddingLeft: 0 }}
      size="lg"
      radius="xl"
      color="gray"
      leftSection={<Avatar size={20} />}
    >
      {user?.name}
    </Badge>
  );
}
