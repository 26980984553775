import React, { useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import IVehicle from '../../models/IVehicle';
import { VehicleForm } from './VehicleForm';
import { useTranslation } from 'react-i18next';

export function VehicleEditDialog(props: { vehicle: IVehicle }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('VehicleEditDialog.Title')}
      >
        <VehicleForm
          create={false}
          vehicle={props.vehicle}
          onFinish={() => setOpened(false)}
        />
      </Modal>

      <ActionIcon
        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
        color="orange"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
