import {
  Chip,
  createStyles,
  Group,
  Paper,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Space,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';

import React, { useContext, useEffect, useState } from 'react';
import IVehicle from '../../models/IVehicle';
import { VehicleCreateDialog } from './VehicleCreateDialog';
import { VehicleEditDialog } from './VehicleEditDialog';
import { VehicleDeleteDialog } from './VehicleDeleteDialog';
import { Icon } from 'leaflet';
import IGeoCoordinates from '../../models/IGeoCoordinates';
import VehicleService from '../../services/VehicleService';
import { AbstractContextType } from '../../context/AbstractProvider';
import { VehicleContext } from '../../context/ContextWrapper';
import { useTranslation } from 'react-i18next';
import { VehicleTypeDialog } from './vehicletypes/VehicleTypeDialog';
import IVehicleType from '../../models/IVehicleType';
import IPackageUnit from '../../models/IPackageUnit';
import moment from 'moment/moment';
import cx from 'clsx';
import { sortVehicle } from '../../utility/sorter';
import { VehicleStatusElement } from './VehicleStatusElement';

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: IVehicle[], onLicensePlateClick: Function) {
  return elements
    .sort((a, b) => sortVehicle(a, b))
    .map((element) => {
      return (
        <tr key={element.id}>
          <td>{element.radio_call_name}</td>
          <td>{(element.type_of as IVehicleType)?.abbreviation}</td>
          <td>{(element.type_of as IVehicleType)?.crew}</td>
          <td>
            <Chip.Group>
              {(element.type_of as IVehicleType)?.has.map((e) => (
                <Chip size="xs" color="blue" checked={true} variant="filled">
                  {e}
                </Chip>
              ))}
            </Chip.Group>
          </td>
          <td>
            <div style={{ display: 'flex' }}>
              <Text>
                {element.max_cargo}{' '}
                {(element.package_unit as IPackageUnit)?.name}
                {','}
              </Text>
              <Space w="xl" />
              <Text>
                {element.max_weight} {element.weight_unit}
              </Text>
            </div>
          </td>
          <td>
            <VehicleStatusElement status={element.status} />
          </td>
          <td>{element.phone}</td>
          <td>
            {moment.utc(element.available_from).local().format('DD.MM.YYYY')}
          </td>
          <td>
            <Group spacing="xs">
              <VehicleEditDialog vehicle={element} />
              <VehicleDeleteDialog vehicle={element} />
            </Group>
          </td>
        </tr>
      );
    });
}

/**
 * Finds the center of points.
 * @param coords
 */
function calcCentroid(coords: IGeoCoordinates[]): IGeoCoordinates {
  let sumLat = 0,
    sumLng = 0;
  coords.forEach((c) => {
    sumLat += c.lat;
    sumLng += c.lng;
  });
  return { lat: sumLat / coords.length, lng: sumLng / coords.length };
}

export default function VehicleTable() {
  /* TODO accordion should be closed at start -> initial load of mapDetails is not necessary
   *   BUT: if so css/map not working properly
   */
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<
    { lat: number; lng: number; tooltip?: string; icon?: Icon }[]
  >([]);
  const ctx = useContext(VehicleContext) as AbstractContextType<IVehicle>;
  const centerVehicle = (lp: string) => {
    const f = marker.filter((m) => m.tooltip == lp);
    if (f.length > 0) {
      setMapCenter(f[0]);
    }
  };
  const [scrolled, setScrolled] = useState(false);

  const loadMapDetails = () => {
    // TODO put map in context ? context amybe has aleardy a map for mangiing update pushes
    const vp = new Map();
    ctx.entities.forEach((v) => vp.set(v.id, v.license_plate));

    VehicleService.getAllGpsCoordinates().then((res) => {
      setMarker(
        res.map((el) => {
          return {
            lat: el.coords.lat,
            lng: el.coords.lng,
            tooltip: vp.get(el.id),
          };
        }),
      );
      setMapCenter(calcCentroid(res.map((vc) => vc.coords)));
    });
  };

  useEffect(() => {
    loadMapDetails();
  }, [ctx.loading]);

  const onAccordionChange = (state: string) => {
    if (state === 'map') {
      loadMapDetails();
    }
  };

  // TODO: use map for tracking vehicles with hardware tracker
  // TODO: finish i18n tranlation for table and form dialog
  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>Fahrzeuge</Title>
        <SimpleGrid cols={2}>
          <Group>
            <VehicleCreateDialog />
          </Group>
          <Group position="right">
            <VehicleTypeDialog />
          </Group>
        </SimpleGrid>
        <ScrollArea
          h={'85vh'}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th>{t('VehicleForm.RadioCallName')}</th>
                <th>{t('VehicleTable.Type')}</th>
                <th>{t('VehicleTable.Crew')}</th>
                <th>{t('VehicleTable.Has')}</th>
                <th>{t('VehicleTable.MaxLoad')}</th>
                <th>{t('VehicleTable.Status')}</th>
                <th>{t('VehicleTable.PhoneNumber')}</th>
                <th>{t('VehicleTable.AvailableFrom')}</th>
                <th>{t('VehicleTable.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(ctx.entities, centerVehicle)}
              {ctx.loading &&
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="20%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="20%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="40%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="40%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">{t('VehicleTable.NoVehiclesAvailable')}</Text>
        )}
      </Stack>
    </Paper>
  );
}
