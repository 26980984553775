import ISection from '../../models/ISection';
import { useTranslation } from 'react-i18next';
import { Button, Container, Divider, Grid, Group, Text } from '@mantine/core';
import React, { useContext } from 'react';
import { ROLE_ENUM_VALUES } from '../../api_enums/ROLE_ENUM';
import { AbstractContextType } from '../../context/AbstractProvider';
import IUser from '../../models/IUser';
import { UserContext } from '../../context/ContextWrapper';

function AssignedUser(props: { user: IUser }) {
  const { t } = useTranslation();
  return (
    <Grid.Col xs={12}>
      <Grid>
        <Grid.Col xs={6}>
          <Text size={'xs'}>{t('SectionDetails.UserName')}</Text>
          <Text>{props.user.name}</Text>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Text size={'xs'}>{t('SectionDetails.PagingName')}</Text>
          <Text>{props.user.radio_call_name}</Text>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Text size={'xs'}>{t('SectionDetails.PhoneNumber')}</Text>
          <Text>{props.user.phone}</Text>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Text size={'xs'}>{t('SectionDetails.OperationsSection')}</Text>
          <Text>
            {typeof props.user.assigned_to !== 'string'
              ? props.user.assigned_to.name
              : props.user.assigned_to}
          </Text>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Divider size="xs" />
        </Grid.Col>
      </Grid>
    </Grid.Col>
  );
}

function Role(props: { role: any }) {
  const { t } = useTranslation();
  return <Text c={props.role[1].color}>{props.role[1].label}</Text>;
}

export function SectionDetails(props: {
  onFinish: Function;
  section?: ISection;
  supervisor?: number | null;
}) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext) as AbstractContextType<IUser>;
  const assignedUsers = userContext.entities.filter(
    (u) =>
      typeof u.assigned_to !== 'string' &&
      u.assigned_to.id === props.section.id,
  );
  const leaderId =
    typeof props.section.led_by === 'string'
      ? props.section.led_by
      : props.section.led_by.id;
  const leader = userContext.entities.filter((u) => u.id === leaderId)[0];
  let supervisor = null;
  if (props.section.supervised_by !== null) {
    supervisor =
      typeof props.section.supervised_by === 'string'
        ? props.section.supervised_by
        : props.section.supervised_by.name;
  }
  return (
    <div style={{ position: 'relative' }}>
      <Container my="md">
        <Grid>
          <Grid.Col xs={6}>
            <Text size={'xs'}>{t('SectionDetails.Name')}</Text>
            <Text>{props.section.name}</Text>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Text size={'xs'}>{t('SectionDetails.Role')}</Text>
            <Text>
              {props.section.roles.map((r) => (
                <Role
                  role={
                    Array.from(ROLE_ENUM_VALUES).filter((rv) => rv[0] === r)[0]
                  }
                />
              ))}
            </Text>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Text size={'xs'}>{t('SectionDetails.Superior')}</Text>
            <Text>{supervisor}</Text>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Text size={'xs'}>{t('SectionDetails.SectionManager')}</Text>
            <Text>{leader.name}</Text>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Text size={'xs'}>{t('SectionDetails.NumberOfEmployees')}</Text>
            <Text>{props.section.number_of_employees}</Text>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Text size={'xs'}>{t('SectionDetails.Description')}</Text>
            <Text>{props.section.description}</Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Text size={'xs'}>{t('SectionDetails.AssignedUsers')}</Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Divider size="xs" />
          </Grid.Col>
          {assignedUsers.length > 0 &&
            assignedUsers.map((rn) => <AssignedUser key={rn.id} user={rn} />)}
          <Grid.Col>
            <Group>
              <Button color="gray" onClick={() => props.onFinish()}>
                {t('main.Close')}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}
