import React, { useState } from 'react';
import { Button, Modal } from '@mantine/core';
import { FilePencil } from 'tabler-icons-react';
import { VehicleTypeForm } from './VehicleTypeForm';
import { useTranslation } from 'react-i18next';

export function VehicleTypeDialog() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button
        color="blue"
        size="md"
        variant="light"
        leftIcon={<FilePencil />}
        onClick={() => setOpened(true)}
      >
        {t('VehicleTypeDialog.ManageVehicleType')}
      </Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="80%%"
        title={t('VehicleTypeDialog.Title')}
      >
        <VehicleTypeForm onFinish={() => setOpened(false)} />
      </Modal>
    </>
  );
}
