// TODO replace and merge with existing OrderPriorityElement
import { INQUIRY_PRIORITY_ENUM_VALUES } from '../../api_enums/INQUIRY_PRIORITY_ENUM';
import { Badge } from '@mantine/core';
import React from 'react';

export function InquiryPriorityElement(props: { value: string }) {
  const prio = INQUIRY_PRIORITY_ENUM_VALUES.get(props.value);

  return (
    <Badge
      sx={{ paddingTop: 4, paddingBottom: 2 }}
      size="md"
      radius="md"
      color={prio?.color || 'gray'}
      variant="outline"
    >
      {prio?.label || props.value}
    </Badge>
  );
}
