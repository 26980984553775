import {
  Box,
  CircleCheck,
  CirclePlus,
  Hexagon,
  Hexagons,
  Inbox,
  QuestionMark,
  ShoppingCart,
  Tir,
  TruckDelivery,
  UserCircle,
} from 'tabler-icons-react';
import { ReactNode } from 'react';

enum ROLE_ENUM {
  UNKNOWN = 'unknown',
  USER = 'user',
  REQUESTOR = 'requester',
  RECIPIENT = 'recipient',
  APPROVER = 'approver',
  PURCHASER = 'purchaser',
  SUPPLIER = 'supplier',
  SHIPPER = 'shipper',
  LOGISTICAN = 'logistician',
  MANAGER = 'manager',
  ADMINISTRATOR = 'administrator',
}

const ROLE_ENUM_VALUES = new Map<
  string,
  { label: string; icon: ReactNode; color: string }
>([
  [
    ROLE_ENUM.ADMINISTRATOR,
    { label: 'Administrator', icon: <Hexagon color={'red'} />, color: 'red' },
  ],
  [
    ROLE_ENUM.MANAGER,
    {
      label: 'Verwalter',
      icon: <Hexagons color={'orange'} />,
      color: 'orange',
    },
  ],
  [
    ROLE_ENUM.SUPPLIER,
    { label: 'Zulieferer', icon: <Box color={'orange'} />, color: 'orange' },
  ],
  [
    ROLE_ENUM.SHIPPER,
    {
      label: 'Spediteur',
      icon: <TruckDelivery color={'lime'} />,
      color: 'lime',
    },
  ],
  [
    ROLE_ENUM.LOGISTICAN,
    { label: 'Logistiker', icon: <Tir color={'green'} />, color: 'green' },
  ],
  [
    ROLE_ENUM.USER,
    { label: 'Nutzer', icon: <UserCircle color={'grey'} />, color: 'grey' },
  ],
  [
    ROLE_ENUM.UNKNOWN,
    {
      label: 'Unbekannt',
      icon: <QuestionMark color={'grey'} />,
      color: 'grey',
    },
  ],
  [
    ROLE_ENUM.APPROVER,
    {
      label: 'Freigeber',
      icon: <CircleCheck color={'darkviolet'} />,
      color: 'violet',
    },
  ],
  [
    ROLE_ENUM.PURCHASER,
    {
      label: 'Einkäufer',
      icon: <ShoppingCart color={'blue'} />,
      color: 'blue',
    },
  ],
  [
    ROLE_ENUM.REQUESTOR,
    { label: 'Anforderer', icon: <CirclePlus color={'cyan'} />, color: 'cyan' },
  ],
  [
    ROLE_ENUM.RECIPIENT,
    { label: 'Empfänger', icon: <Inbox color={'teal'} />, color: 'teal' },
  ],
]);

export { ROLE_ENUM, ROLE_ENUM_VALUES };
