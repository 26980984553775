import 'dayjs/locale/de';
import React, { useContext, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import IVehicle from '../../models/IVehicle';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import { DatePicker } from '@mantine/dates';
import VehicleService from '../../services/VehicleService';
import ISection from '../../models/ISection';
import WEIGHT_UNIT_ENUM from '../../api_enums/WEIGHT_UNIT_ENUM';
import {
  PackageUnitContext,
  SectionContext,
  VehicleTypeContext,
} from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { useTranslation } from 'react-i18next';
import IVehicleType from '../../models/IVehicleType';
import IPackageUnit from '../../models/IPackageUnit';

// TODO replace with type selects
// TODO min max length and value
export function VehicleForm(props: {
  create: boolean;
  onFinish: Function;
  vehicle?: IVehicle;
}) {
  const { t } = useTranslation();
  const PackageUnitCtx = useContext(
    PackageUnitContext,
  ) as AbstractContextType<IPackageUnit>;
  const sectionCtx = useContext(
    SectionContext,
  ) as AbstractContextType<ISection>;
  const vehicleTypeCtx = useContext(
    VehicleTypeContext,
  ) as AbstractContextType<IVehicleType>;

  const [visible, setVisible] = useState(false);

  const saveOrUpdateVehicle = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      props.onFinish();
    };

    if (props.create) {
      VehicleService.create(form.values as IVehicle)
        .then(() => {
          showAppNotifcation(true, t('VehicleForm.VehicleCreate'));
        })
        .catch(() =>
          showAppNotifcation(false, t('VehicleForm.VehicleCanNotCreate')),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (!props.create && props.vehicle) {
      VehicleService.patch(props.vehicle.id, form.values as IVehicle)
        .then(() => {
          showAppNotifcation(true, t('VehicleForm.VehicleUpdated'));
        })
        .catch(() =>
          showAppNotifcation(false, t('VehicleForm.VehicleCanNotChange')),
        ) // TODO logging react
        .finally(finallyHandler);
    } else {
      console.error('update needs a vehicle object');
    }
  };

  const form = useForm({
    initialValues: {
      type: 'unknown',
      radio_call_name: props.vehicle?.radio_call_name || '',
      license_plate: props.vehicle?.license_plate || '',
      cargo_space_description: props.vehicle?.cargo_space_description || '',
      max_cargo: props.vehicle?.max_cargo || 0,
      package_unit: (
        props.vehicle?.package_unit as IPackageUnit
      )?.id.toString(),
      max_weight: props.vehicle?.max_weight || 0,
      weight_unit: props.vehicle?.weight_unit || 'kg',
      max_volume: props.vehicle?.max_volume || 0,
      volume_unit: props.vehicle?.volume_unit || 'm3',
      available_from: props.vehicle?.available_from || new Date(),
      available_until: props.vehicle?.available_until || null,
      owner_description: props.vehicle?.owner_description || '',
      phone: props.vehicle?.phone || '',
      is_active: true,
      type_of: (props.vehicle?.type_of as IVehicleType)?.id.toString() || null,
    },
    validate: {
      type_of: (value) =>
        value == null ? t('VehicleForm.SelectVehicleType') : null,
      package_unit: (value) =>
        value == null ? t('VehicleForm.SelectPackageUnit') : null,
    },
  });

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md">
        <form onSubmit={form.onSubmit(() => saveOrUpdateVehicle())}>
          <Grid>
            <Grid.Col xs={8}>
              <TextInput
                placeholder="Alpha 1"
                label={t('VehicleForm.RadioCallName')}
                required
                {...form.getInputProps('radio_call_name')}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <TextInput
                placeholder="DGF-XX-1234"
                label={t('VehicleForm.LicensePlate')}
                {...form.getInputProps('license_plate')}
              />
            </Grid.Col>{' '}
            <Grid.Col xs={8}>
              <Select
                label={t('VehicleForm.VehicleType')}
                placeholder={t('VehicleForm.VehicleType')}
                required
                data={vehicleTypeCtx.entities.map((s) => {
                  return { value: s.id.toString(), label: s.name };
                })}
                {...form.getInputProps('type_of')}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <TextInput
                placeholder={t('VehicleForm.PhoneNumberTooltip')}
                label={t('VehicleTable.PhoneNumber')}
                {...form.getInputProps('phone')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Auflieger"
                label={t('VehicleForm.CargoHoldDescription')}
                required
                {...form.getInputProps('cargo_space_description')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <NumberInput
                placeholder=""
                label="Max. Zuladekapazität"
                required
                min={0}
                {...form.getInputProps('max_cargo')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={t('VehicleForm.PackageUnit')}
                placeholder="Einheit"
                required
                data={PackageUnitCtx.entities.map((s) => {
                  return { value: s.id.toString(), label: s.name };
                })}
                {...form.getInputProps('package_unit')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <NumberInput
                placeholder=""
                label="Max. Zuladegewicht"
                required
                min={0}
                {...form.getInputProps('max_weight')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label="Gewichtseinheit"
                placeholder="Einheit"
                required
                data={Object.values(WEIGHT_UNIT_ENUM).map((e) => {
                  return { value: e, label: e };
                })}
                {...form.getInputProps('weight_unit')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <DatePicker
                locale="de"
                placeholder={t('VehicleForm.SelectDate')}
                label={t('VehicleForm.AvailableFrom')}
                required
                {...form.getInputProps('available_from')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <DatePicker
                locale="de"
                placeholder={t('VehicleForm.SelectDate')}
                label={t('VehicleForm.AvailableUntil')}
                {...form.getInputProps('available_until')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="FFW Passau"
                label={t('VehicleForm.OwnerDescription')}
                {...form.getInputProps('owner_description')}
              />
            </Grid.Col>
            <Grid.Col>
              <Group>
                <Button color={props.create ? 'green' : 'orange'} type="submit">
                  {props.create
                    ? t('VehicleForm.CreateVehicle')
                    : t('VehicleForm.SaveVehicle')}
                </Button>
                <Button color="gray" onClick={() => props.onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
