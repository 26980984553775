import React, { useState } from 'react';
import { Button, Modal } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { VehicleForm } from './VehicleForm';
import { useTranslation } from 'react-i18next';

export function VehicleCreateDialog() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button
        color="green"
        size="md"
        variant="light"
        leftIcon={<Plus />}
        onClick={() => setOpened(true)}
      >
        {t('VehicleCreateDialog.NewVehicle')}
      </Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="lg"
        title={t('VehicleCreateDialog.Title')}
      >
        <VehicleForm create={true} onFinish={() => setOpened(false)} />
      </Modal>
    </>
  );
}
