import axios from '../config/axios';
import { AxiosResponse } from 'axios';

export default abstract class AbstractCRUDService<E> {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async findAll(): Promise<AxiosResponse<E[]>> {
    return axios.get<E[]>(`${this.basePath}/find/all`);
  }

  async create(obj: E): Promise<AxiosResponse<E>> {
    return axios.post(`${this.basePath}/create`, obj);
  }

  async remove(objId: number): Promise<AxiosResponse<E>> {
    return axios.delete(`${this.basePath}/remove/${objId}`);
  }

  async patch(objId: number, obj: Partial<E>): Promise<E> {
    return axios.patch(`${this.basePath}/change/${objId}`, obj);
  }
}
