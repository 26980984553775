
enum VOLUME_UNIT_ENUM {
    M3 = 'm3'
}

const VOLUME_UNIT_ENUM_VALUES = new Map<string, { label: string}>([
    [VOLUME_UNIT_ENUM.M3, {label: 'm³'}]
]);

export  {
    VOLUME_UNIT_ENUM,
    VOLUME_UNIT_ENUM_VALUES
};
