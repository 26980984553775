import {
  FilePlus,
  Icon,
  ReportMoney,
  TrafficLights,
  TruckDelivery,
  TruckReturn,
} from 'tabler-icons-react';

enum ORDER_STATUS_ENUM {
  PICKING = 'picking',
  LOADED = 'loaded',
  PARTIALLY_DISPATCHED = 'partially_dispatched',
  DISPATCHED = 'dispatched',
  CANCELLED = 'cancelled',
  ON_DELIVERY = 'on_delivery',
  DELIVERED = 'delivered',
  PARTIALLY_DELIVERED = 'partially_delivered',
  NOT_DELIVERED = 'not_delivered',
  PARTIALLY_LOADED = 'partially_loaded',
}

const ORDER_STATUS_ENUM_VALUES = new Map<
  string,
  { label: string; icon: Icon; color: string; priority: number }
>([
  [
    ORDER_STATUS_ENUM.PICKING,
    { label: 'Wird vorbereitet', icon: FilePlus, color: 'dark', priority: 0 },
  ],
  [
    ORDER_STATUS_ENUM.PARTIALLY_LOADED,
    { label: 'Teilweise Verladen', icon: FilePlus, color: 'grey', priority: 1 },
  ],

  [
    ORDER_STATUS_ENUM.PARTIALLY_DELIVERED,
    {
      label: 'Teilweise Ausgeliefert',
      icon: TrafficLights,
      color: 'grey',
      priority: 2,
    },
  ],
  [
    ORDER_STATUS_ENUM.LOADED,
    { label: 'Verladen', icon: FilePlus, color: 'yellow', priority: 3 },
  ],

  [
    ORDER_STATUS_ENUM.DELIVERED,
    { label: 'Ausgeliefert', icon: TrafficLights, color: 'green', priority: 4 },
  ],
  [
    ORDER_STATUS_ENUM.DISPATCHED,
    { label: 'Ausgeliefert', icon: ReportMoney, color: 'green', priority: 5 },
  ],
  [
    ORDER_STATUS_ENUM.PARTIALLY_DISPATCHED,
    {
      label: 'Teilweise ausgeliefert',
      icon: TrafficLights,
      color: 'orange',
      priority: 6,
    },
  ],
  [
    ORDER_STATUS_ENUM.ON_DELIVERY,
    { label: 'Unterwegs', icon: FilePlus, color: 'orange', priority: 7 },
  ],
  [
    ORDER_STATUS_ENUM.CANCELLED,
    { label: 'Storniert', icon: TruckDelivery, color: 'grey', priority: 8 },
  ],
  [
    ORDER_STATUS_ENUM.NOT_DELIVERED,
    { label: 'Nicht Geliefert', icon: TruckReturn, color: 'grey', priority: 9 },
  ],
]);

export { ORDER_STATUS_ENUM, ORDER_STATUS_ENUM_VALUES };
