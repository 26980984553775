import AbstractCRUDService from './AbstractCRUDService';
import { IDeliveryPosition } from '../models/IDeliveryPosition';
import { AxiosResponse } from 'axios';
import IInquiry from '../models/IInquiry';
import axios from '../config/axios';

class DeliveryPositionService extends AbstractCRUDService<IDeliveryPosition> {
  receivingBasePath = 'receiving/delivery';

  async receive(
    objId: number,
    receivedAmount: number,
  ): Promise<AxiosResponse<IInquiry>> {
    const obj = {
      delivery_position_id: objId,
      received_amount: receivedAmount,
    };
    return axios.patch(`${this.receivingBasePath}/receive`, obj);
  }

  async reject(objId: number): Promise<AxiosResponse<IInquiry>> {
    const obj = {
      delivery_position_id: objId,
    };
    return axios.patch(`${this.receivingBasePath}/reject`, obj);
  }
}

export default new DeliveryPositionService('shipping/delivery');
