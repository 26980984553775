import { Grid, Stack } from '@mantine/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderPositionTable from './Order/OrderPositionTable';
import DeliveryTable from './Delivery/DeliveryTable';

function ShipmentPanel() {
  const { t } = useTranslation();
  return (
    <Stack>
      <Grid>
        <Grid.Col md={6} lg={6}>
          <Stack>
            <OrderPositionTable />
          </Stack>
        </Grid.Col>
        <Grid.Col md={6} lg={6}>
          <Stack>
            <DeliveryTable />
          </Stack>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}

export default ShipmentPanel;
