import { Modal } from '@mantine/core';
import { SectionDetails } from './SectionDetails';
import ISection from '../../models/ISection';
import { useTranslation } from 'react-i18next';

export function SectionDetailDialog(props: {
  section: ISection;
  opened: boolean;
  setOpened: Function;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      size="lg"
      title={t('SectionDetailDialog.Title')}
      trapFocus={false}
    >
      <SectionDetails
        section={props.section}
        onFinish={() => props.setOpened(false)}
      />
    </Modal>
  );
}
