import React, { useState } from 'react';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import OperationService from '../../services/OperationService';
import { showAppNotifcation } from '../../utility/NotificationConfigs';

export function ArchiveConfirmDialog() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const archiveOperation = () => {
    OperationService.archive()
      .then(() => {
        showAppNotifcation(true, t('ArchiveConfirmDialog.SuccessMsg'));
      })
      .catch(() => showAppNotifcation(false, t('ArchiveConfirmDialog.FailMsg')))
      .finally(() => setOpened(false));
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('ArchiveConfirmDialog.Title')}
      >
        <Stack>
          <Text>{t('ArchiveConfirmDialog.Text')}</Text>
          <Group>
            <Button color="red" onClick={archiveOperation}>
              {t('main.Archive')}
            </Button>
            <Button color="gray" onClick={() => setOpened(false)}>
              {t('main.Cancel')}
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Button color="red" onClick={() => setOpened(true)}>
        {t('main.Archive')}
      </Button>
    </>
  );
}
