import {
  createStyles,
  Group,
  Navbar,
  ScrollArea,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  Checklist,
  File3d,
  Hierarchy2,
  Icon as TablerIcon,
  Logout,
  Map2,
  Notes,
  Settings,
  ShoppingCart,
  Truck,
  TruckDelivery,
  TruckLoading,
  Users,
} from 'tabler-icons-react';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext, AuthContextType } from '../context/AuthProvider';
import { ROLE_ENUM, ROLE_ENUM_VALUES } from '../api_enums/ROLE_ENUM';
import { useTranslation } from 'react-i18next';

interface NavbarLinkProps {
  icon: TablerIcon;
  label: string;
  path: string;
  color: string;
}

const useStyles = createStyles((theme) => ({
  link: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  standard_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors[theme.primaryColor][8], 0.25)
          : theme.colors[theme.primaryColor][1],
      color:
        theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  red_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['red'][9], 0.25)
          : theme.colors['red'][0],
      color: theme.colors['red'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  orange_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['orange'][9], 0.25)
          : theme.colors['orange'][0],
      color: theme.colors['orange'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  yellow_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['yellow'][9], 0.25)
          : theme.colors['yellow'][0],
      color: theme.colors['yellow'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  lime_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['lime'][9], 0.25)
          : theme.colors['lime'][0],
      color: theme.colors['lime'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  green_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['green'][9], 0.25)
          : theme.colors['green'][0],
      color: theme.colors['green'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  black_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['dark'][9], 0.25)
          : theme.colors['dark'][0],
      color: theme.colors['dark'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  grape_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['grape'][9], 0.25)
          : theme.colors['grape'][0],
      color: theme.colors['grape'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  cyan_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['cyan'][9], 0.25)
          : theme.colors['cyan'][0],
      color: theme.colors['cyan'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  blue_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['blue'][9], 0.25)
          : theme.colors['blue'][0],
      color: theme.colors['blue'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },

  teal_active: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors['teal'][9], 0.25)
          : theme.colors['teal'][0],
      color: theme.colors['teal'][theme.colorScheme === 'dark' ? 4 : 7],
    },
  },
}));

function getColorStyle(color, classes) {
  if (color === 'red') return classes.red_active;
  if (color === 'orange') return classes.orange_active;
  if (color === 'yellow') return classes.yellow_active;
  if (color === 'lime') return classes.lime_active;
  if (color === 'green') return classes.green_active;
  if (color === 'black') return classes.black_active;
  if (color === 'grape') return classes.grape_active;
  if (color === 'cyan') return classes.cyan_active;
  if (color === 'blue') return classes.blue_active;
  if (color === 'teal') return classes.teal_active;
  return classes.standard_active;
}

function NavbarLink({ icon: Icon, label, path, color }: NavbarLinkProps) {
  const { classes, cx } = useStyles();
  const loc = useLocation();

  return (
    <Tooltip.Floating label={label} position="right">
      <Link to={path}>
        <UnstyledButton
          className={cx(classes.link, {
            [getColorStyle(color, classes)]: loc.pathname.endsWith(path),
          })}
        >
          <Icon size={30} />
        </UnstyledButton>
      </Link>
    </Tooltip.Floating>
  );
}

export function AppNavbar() {
  const { t } = useTranslation();
  const { logout, hasRight } = useContext(AuthContext) as AuthContextType;

  const mockdata = [
    {
      icon: Notes,
      label: t('AppNavbar.Requirements'),
      path: 'inquiries',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.REQUESTOR).color,
      roles: [ROLE_ENUM.REQUESTOR],
    },
    {
      icon: Checklist,
      label: t('AppNavbar.ReleaseRequirements'),
      path: 'inquiries_approve',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.APPROVER).color,
      roles: [ROLE_ENUM.APPROVER],
    },
    {
      icon: ShoppingCart,
      label: t('AppNavbar.OrderRequirements'),
      path: 'inquiries_purchase',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.PURCHASER).color,
      roles: [ROLE_ENUM.PURCHASER],
    },
    {
      icon: TruckDelivery,
      label: t('AppNavbar.SendOrders'),
      path: 'orders_ship',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.SUPPLIER).color,
      roles: [ROLE_ENUM.SHIPPER, ROLE_ENUM.SUPPLIER, ROLE_ENUM.LOGISTICAN],
    },
    {
      icon: Map2,
      label: t('AppNavbar.DeliveryLocations'),
      path: 'locations',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.REQUESTOR).color,
      roles: [ROLE_ENUM.REQUESTOR, ROLE_ENUM.RECIPIENT],
    },
    {
      icon: Settings,
      label: t('AppNavbar.Settings'),
      path: 'settings',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.USER).color,
      roles: [ROLE_ENUM.USER],
    },
    {
      icon: File3d,
      label: t('AppNavbar.Resource'),
      path: 'resources',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.SUPPLIER).color,
      roles: [ROLE_ENUM.SUPPLIER],
    },
    {
      icon: Truck,
      label: t('AppNavbar.Vehicles'),
      path: 'vehicles',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.LOGISTICAN).color,
      roles: [ROLE_ENUM.LOGISTICAN],
    },
    {
      icon: Users,
      label: t('AppNavbar.Users'),
      path: 'users',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.MANAGER).color,
      roles: [ROLE_ENUM.MANAGER],
    },
    {
      icon: Hierarchy2,
      label: t('AppNavbar.OperationalSections'),
      path: 'sections',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.MANAGER).color,
      roles: [ROLE_ENUM.USER, ROLE_ENUM.MANAGER],
    },
    {
      icon: TruckLoading,
      label: t('AppNavbar.PackagingUnit'),
      path: 'packageunit',
      color: ROLE_ENUM_VALUES.get(ROLE_ENUM.SUPPLIER).color,
      roles: [ROLE_ENUM.SUPPLIER, ROLE_ENUM.LOGISTICAN],
    },
  ];

  const links = mockdata.map((link) =>
    hasRight(link.roles) ? (
      <NavbarLink
        {...link}
        key={link.label}
        path={link.path}
        color={link.color}
      />
    ) : (
      <></>
    ),
  );

  const { classes, cx } = useStyles();
  return (
    <Navbar width={{ base: 85 }} p="md">
      <Navbar.Section mt={50} grow component={ScrollArea} mx="-xs" px="xs">
        <Group align="center" spacing={0}>
          {links}
        </Group>
      </Navbar.Section>
      <Navbar.Section>
        <Group align="center" spacing={0}>
          <Tooltip
            label={t('AppNavbar.Logout')}
            position="right"
            withArrow
            transitionDuration={0}
          >
            <UnstyledButton className={cx(classes.link)} onClick={logout}>
              <Logout color="red" size={30} />
            </UnstyledButton>
          </Tooltip>
        </Group>
      </Navbar.Section>
    </Navbar>
  );
}
