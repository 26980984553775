import React, { useContext, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Switch,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import ResourceService from '../../services/ResourceService';
import IResource from '../../models/IResource';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import WEIGHT_UNIT_ENUM from '../../api_enums/WEIGHT_UNIT_ENUM';
import { VOLUME_UNIT_ENUM } from '../../api_enums/VOLUME_UNIT_ENUM';
import {
  RESOURCE_TYPE_ENUM,
  RESOURCE_TYPE_ENUM_VALUES,
} from '../../api_enums/RESOURCE_TYPE_ENUM';
import { useTranslation } from 'react-i18next';
import { PackageUnitContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import IPackageUnit from '../../models/IPackageUnit';

export function ResourceForm(props: {
  create: boolean;
  onFinish: Function;
  resource?: IResource;
}) {
  const { t } = useTranslation();
  const PackageUnitCtx = useContext(
    PackageUnitContext,
  ) as AbstractContextType<IPackageUnit>;
  const [visible, setVisible] = useState(false);

  const saveOrUpdateLocation = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      props.onFinish();
    };

    if (props.create) {
      ResourceService.create(form.values as IResource)
        .then(() => {
          showAppNotifcation(true, 'Ressource wurde erfolgreich angelegt');
        })
        .catch(() =>
          showAppNotifcation(false, 'Ressource konnte nicht angelegt werden'),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (!props.create && props.resource) {
      ResourceService.patch(props.resource.id, form.values as IResource)
        .then(() => {
          showAppNotifcation(true, 'Ressource wurde erfolgreich geändert');
        })
        .catch(() =>
          showAppNotifcation(false, 'Ressource konnte nicht geändert werden'),
        ) // TODO logging react
        .finally(finallyHandler);
    } else {
      console.error('update needs a location object');
    }
  };

  const form = useForm({
    initialValues: {
      id: props.resource?.id || '',
      name: props.resource?.name || '',
      type: props.resource?.type || RESOURCE_TYPE_ENUM.MATERIAL,
      package_unit:
        (props.resource?.package_unit as IPackageUnit)?.id.toString() || '',
      weight: props.resource?.weight || 0,
      weight_unit: props.resource?.weight_unit || WEIGHT_UNIT_ENUM.KG,
      volume: props.resource?.volume || 0,
      volume_unit: props.resource?.volume_unit || VOLUME_UNIT_ENUM.M3,
      is_active: props.resource ? props.resource?.is_active : true,
    },
    validate: {
      package_unit: (value) =>
        value.length < 1 || null ? t('ResourceForm.SelectPackagingUnit') : null,
    },
  });

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md">
        <form onSubmit={form.onSubmit(() => saveOrUpdateLocation())}>
          <Grid>
            <Grid.Col xs={12}>
              <TextInput
                placeholder={t('ResourceEditDialog.Name')}
                label={t('ResourceEditDialog.Name')}
                required
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={t('ResourceEditDialog.Type')}
                placeholder={RESOURCE_TYPE_ENUM.MATERIAL}
                required
                data={Array.from(RESOURCE_TYPE_ENUM_VALUES).map((t) => {
                  return { value: t[0], label: t[1].label };
                })}
                {...form.getInputProps('type')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={t('ResourceEditDialog.PackagingUnit')}
                placeholder={t('ResourceEditDialog.PackagingUnit')}
                required
                data={PackageUnitCtx.entities.map((s) => {
                  return { value: s.id.toString(), label: s.name };
                })}
                {...form.getInputProps('package_unit')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <NumberInput
                placeholder="0"
                label={t('ResourceEditDialog.Weight')}
                required
                defaultValue={0.0}
                precision={2}
                step={0.5}
                min={0}
                {...form.getInputProps('weight')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={t('ResourceEditDialog.WeightUnit')}
                placeholder={WEIGHT_UNIT_ENUM.KG}
                required
                data={Array.from(Object.values(WEIGHT_UNIT_ENUM))}
                {...form.getInputProps('weight_unit')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <NumberInput
                placeholder="0"
                label={t('ResourceEditDialog.Volume')}
                required
                defaultValue={0.0}
                precision={2}
                step={0.5}
                min={0}
                {...form.getInputProps('volume')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={t('ResourceEditDialog.VolumeUnit')}
                placeholder={VOLUME_UNIT_ENUM.M3}
                required
                data={Array.from(Object.values(VOLUME_UNIT_ENUM))}
                {...form.getInputProps('volume_unit')}
              />
            </Grid.Col>
            {!props.create && (
              <Grid.Col xs={6}>
                <Switch
                  color="lime"
                  label={t('ResourceEditDialog.Active')}
                  {...form.getInputProps('is_active', { type: 'checkbox' })}
                />
              </Grid.Col>
            )}

            <Grid.Col>
              <Group>
                <Button color={props.create ? 'green' : 'orange'} type="submit">
                  {props.create
                    ? t('ResourceEditDialog.CreateResource')
                    : t('ResourceEditDialog.SaveResource')}
                </Button>
                <Button color="gray" onClick={() => props.onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
