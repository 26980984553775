import IInquiry, { getPrefixedInquiryId } from '../../models/IInquiry';
import { ActionIcon, Modal, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';

export function InquiryCommentDialog(props: {
  comment: IInquiry['comment'];
  id: IInquiry['id'];
}) {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('InquiryCommentDialog.title', {
          id: getPrefixedInquiryId(props.id),
        })}
      >
        <Stack>
          <Text style={{ fontWeight: 'bold' }}>{props.comment}</Text>
        </Stack>
      </Modal>
      <ActionIcon
        onClick={() => setOpened(true)}
        color="yellow"
        size="lg"
        title={t('InquiryCommentDialog.title', {
          id: getPrefixedInquiryId(props.id),
        })}
      >
        <InfoCircle />
      </ActionIcon>
    </>
  );
}
