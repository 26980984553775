import IGeoCoordinates from './IGeoCoordinates';
import IVehicle from './IVehicle';
import IUser from './IUser';
import { DELIVERY_STATUS_ENUM } from '../api_enums/DELIVERY_STATUS_ENUM';
import { IDeliveryPosition } from './IDeliveryPosition';
import ISection from './ISection';

export default interface IDelivery {
  id: number;
  status: DELIVERY_STATUS_ENUM;
  vehicle: number;
  delivered_through: Partial<IVehicle> | number | string;
  state: string;
  created_at: Date | string;
  created_by: Partial<IUser> | number;
  startCoods: IGeoCoordinates; // filled when loading a truck
  positions: IDeliveryPosition[];
  loaded_amount: number;
  assigned_to: Partial<ISection> | number | string;
}

export function getPrefixedDeliveryId(id: number) {
  return 'DE-' + id;
}

export function hasDeliveryPrefix(pid: string) {
  return pid.match('^DE-[0-9]+$');
}

export function getDeliveryIdFromPid(pid: string): number {
  return parseInt(pid.substring('DE-'.length));
}
