import React, { forwardRef, useContext, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import InquiryService from '../../services/InquiryService';
import IInquiry from '../../models/IInquiry';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import {
  INQUIRY_PRIORITY_ENUM,
  INQUIRY_PRIORITY_ENUM_VALUES,
} from '../../api_enums/INQUIRY_PRIORITY_ENUM';
import { INQUIRY_STATUS_ENUM } from '../../api_enums/INQUIRY_STATUS_ENUM';
import IUser from '../../models/IUser';
import ILocation from '../../models/ILocation';
import IResource from '../../models/IResource';
import ISection from '../../models/ISection';
import {
  LocationContext,
  ResourceContext,
  SectionContext,
} from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { InquiryPriorityElement } from './InquiryPriorityElement';
import { useTranslation } from 'react-i18next';
import IPackageUnit from '../../models/IPackageUnit';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { sortResource } from '../../utility/sorter';

export enum InquiryFormMode {
  CREATE,
  EDIT,
  APPROVE, // TODO not an option right now
}

export function InquiryForm(props: {
  mode: InquiryFormMode;
  onFinish: Function;
  inquiry?: IInquiry;
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const locationCtx = useContext(
    LocationContext,
  ) as AbstractContextType<ILocation>;
  const resourceCtx = useContext(
    ResourceContext,
  ) as AbstractContextType<IResource>;
  const sectionCtx = useContext(SectionContext);
  const { userInfo } = useContext(AuthContext) as AuthContextType;

  /**
   * Inquiry amount can be increase if it is not approved by the user's higher section.
   * If the status is IN_APPROVAL, it means it is approved by the higher section.
   */
  const checkPermission = () => {
    return (
      !(
        (props.inquiry?.status as INQUIRY_STATUS_ENUM) ===
        INQUIRY_STATUS_ENUM.IN_APPROVAL
      ) ||
      (props.inquiry?.is_waiting_at as ISection)?.id === userInfo?.section_id
    );
  };

  const getSuperVisedSections = () => {
    let superVisedSections: ISection[] = [];
    for (const section of sectionCtx.entities) {
      if (userInfo.section_id === (section.supervised_by as ISection)?.id) {
        superVisedSections.push(section);
      }
    }
    return superVisedSections;
  };

  const getSubSections = (section: ISection) => {
    let subSections: ISection[] = [];
    for (const s of sectionCtx.entities) {
      if ((s.supervised_by as ISection)?.id === section.id) {
        subSections.push(s);
      }
    }
    return subSections;
  };

  const checkPermissionLocation = (section: ISection, location: ILocation) => {
    if (section.id === (location.belongs_to as ISection)?.id) {
      return true;
    }
    const subSections = getSubSections(section);
    for (const s of subSections) {
      if (checkPermissionLocation(s, location)) {
        return true;
      }
    }
  };

  const showLocation = (location: ILocation) => {
    if (userInfo.section_id === (location.belongs_to as ISection)?.id) {
      return true;
    }
    const superVisedSections = getSuperVisedSections();
    if (superVisedSections.length === 0) {
      return false;
    }
    for (const section of superVisedSections) {
      if (checkPermissionLocation(section, location)) {
        return true;
      }
    }
    return false;
  };

  const saveOrUpdateLocation = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      props.onFinish();
    };

    if (props.mode == InquiryFormMode.CREATE) {
      InquiryService.create(form.values as IInquiry)
        .then(() => {
          showAppNotifcation(
            true,
            t('InquiryForm.RequirementCreatedSuccessfully'),
          );
        })
        .catch(() =>
          showAppNotifcation(false, t('InquiryForm.CanNotCreatRequirement')),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (props.mode == InquiryFormMode.EDIT && props.inquiry) {
      InquiryService.patch(props.inquiry.id, form.values as IInquiry)
        .then(() => {
          showAppNotifcation(
            true,
            t('InquiryForm.RequirementChangedSuccessfully'),
          );
        })
        .catch(() =>
          showAppNotifcation(false, t('InquiryForm.CanNotChangRequirement')),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (props.mode == InquiryFormMode.APPROVE && props.inquiry) {
    } else {
      console.error('error, todo better msg');
    }
  };

  const form = useForm({
    initialValues: {
      id: props.inquiry?.id || '',
      status: props.inquiry?.status || INQUIRY_STATUS_ENUM.INQUIRED,
      history: props.inquiry?.history || '',
      priority: props.inquiry?.priority || INQUIRY_PRIORITY_ENUM.ONE_HOUR,
      amount: props.inquiry?.amount || 1,
      inquired_by: (props.inquiry?.inquired_by as IUser)?.id.toString() || '',
      deliver_to: (props.inquiry?.deliver_to as ILocation)?.id.toString() || '',
      inquired_is:
        (props.inquiry?.inquired_is as IResource)?.id.toString() || '',
      is_waiting_at:
        (props.inquiry?.is_waiting_at as ISection)?.id.toString() || '',
      requester: props.inquiry?.requester,
      requester_info: props.inquiry?.requester_info,
      recipient: props.inquiry?.recipient,
      recipient_info: props.inquiry?.recipient_info,
      comment: props.inquiry?.comment,
    },
    validate: {
      amount: (value) => (value <= 0 ? 'Anzahl muss größer 0 sein' : null),
      deliver_to: (value) =>
        value.length < 1 || null ? t('InquiryForm.SelectDeliverTo') : null,
      inquired_is: (value) =>
        value.length < 1 || null ? t('InquiryForm.SelectInquiredBy') : null,
      requester: (value) => (value.length <= 0 ? '' : null),
      recipient: (value) => (value.length <= 0 ? '' : null),
      requester_info: (value) => (value.length <= 0 ? '' : null),
    },
  });

  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, color, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <InquiryPriorityElement value={value} />
      </div>
    ),
  );

  let maxAmount = checkPermission() ? Infinity : props.inquiry?.amount;

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md">
        <form onSubmit={form.onSubmit(() => saveOrUpdateLocation())}>
          <Grid>
            <Grid.Col xs={6}>
              <Select
                label={t('main.Priority')}
                placeholder={
                  INQUIRY_PRIORITY_ENUM_VALUES.get(
                    INQUIRY_PRIORITY_ENUM.ONE_HOUR,
                  ).label
                }
                itemComponent={SelectItem}
                data={Array.from(INQUIRY_PRIORITY_ENUM_VALUES).map((s) => {
                  return { value: s[0], label: s[1].label, color: s[1].color };
                })}
                {...form.getInputProps('priority')}
              />
            </Grid.Col>

            <Grid.Col xs={6}>
              <NumberInput
                id={'numberInputAmount'}
                placeholder="0"
                label={t('InquiryForm.Quantity')}
                required
                min={0}
                max={maxAmount}
                {...form.getInputProps('amount')}
              />
            </Grid.Col>

            <Grid.Col xs={12}>
              <Select
                label={t('main.DeliveryLocation')}
                placeholder={t('main.DeliveryLocation')}
                required
                data={locationCtx.entities
                  .filter((s) => showLocation(s))
                  .map((s) => ({ value: s.id.toString(), label: s.name }))}
                {...form.getInputProps('deliver_to')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Select
                label={t('main.Resource')}
                placeholder={t('main.Resource')}
                required
                disabled={props.mode === InquiryFormMode.APPROVE}
                data={resourceCtx.entities
                  .sort((a, b) => sortResource(a, b))
                  .map((s) => {
                    return {
                      value: s.id.toString(),
                      label:
                        s.name +
                        ' (' +
                        (s.package_unit as IPackageUnit)?.name +
                        ')',
                    };
                  })}
                {...form.getInputProps('inquired_is')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder={t('InquiryForm.requester')}
                label={t('InquiryForm.requester')}
                required
                {...form.getInputProps('requester')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder={t('InquiryForm.requester_info')}
                label={t('InquiryForm.requester_info')}
                required
                {...form.getInputProps('requester_info')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder={t('InquiryForm.recipient')}
                label={t('InquiryForm.recipient')}
                required
                {...form.getInputProps('recipient')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder={t('InquiryForm.recipient_info')}
                label={t('InquiryForm.recipient_info')}
                {...form.getInputProps('recipient_info')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <TextInput
                placeholder={t('InquiryForm.comment')}
                label={t('InquiryForm.comment')}
                {...form.getInputProps('comment')}
              />
            </Grid.Col>
            <Grid.Col>
              <Group>
                <Button
                  color={
                    props.mode === InquiryFormMode.APPROVE
                      ? 'green'
                      : props.mode === InquiryFormMode.CREATE
                        ? 'green'
                        : 'orange'
                  }
                  type="submit"
                >
                  {props.mode === InquiryFormMode.APPROVE
                    ? t('InquiryForm.Unlock')
                    : props.mode === InquiryFormMode.CREATE
                      ? t('InquiryForm.CreateRequirementRequest')
                      : t('InquiryForm.SaveRequirementRequest')}
                </Button>
                <Button color="gray" onClick={() => props.onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
