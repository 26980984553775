import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  Switch,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import PackageUnitService from '../../services/PackageUnitService';
import IPackageUnit from '../../models/IPackageUnit';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import { useTranslation } from 'react-i18next';

export function PackageUnitForm(props: {
  create: boolean;
  onFinish: Function;
  packageUnit?: IPackageUnit;
  supervisor?: number | null;
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const saveOrUpdatePackageUnit = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      props.onFinish();
    };

    if (props.create) {
      PackageUnitService.create(form.values as IPackageUnit)
        .then(() => {
          showAppNotifcation(
            true,
            t('PackageUnitForm.PackageUnitCreatedSuccessfully'),
          );
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('PackageUnitForm.PackageUnitCannotCreate'),
          ),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (!props.create && props.packageUnit) {
      PackageUnitService.patch(
        props.packageUnit.id,
        form.values as IPackageUnit,
      )
        .then(() => {
          showAppNotifcation(
            true,
            t('PackageUnitForm.PackageUnitSuccessfullyChanged'),
          );
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('PackageUnitForm.PackageUnitCouldNotBeChanged'),
          ),
        ) // TODO logging react
        .finally(finallyHandler);
    } else {
      console.error('update needs a PackageUnit object');
    }
  };

  const form = useForm({
    initialValues: {
      name: props.packageUnit?.name || '',
      description: props.packageUnit?.description || '',
      is_active: props.packageUnit ? props.packageUnit.is_active : true,
    },
  });

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md">
        <form onSubmit={form.onSubmit(() => saveOrUpdatePackageUnit())}>
          <Grid>
            <Grid.Col xs={12}>
              <TextInput
                placeholder="Name"
                label="Name"
                required
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Textarea
                placeholder={t('PackageUnitForm.Description')}
                label={t('PackageUnitForm.Description')}
                {...form.getInputProps('description')}
              />
            </Grid.Col>

            {!props.create && (
              <Grid.Col xs={4}>
                <Switch
                  color="lime"
                  label="Aktiv"
                  {...form.getInputProps('is_active', { type: 'checkbox' })}
                />
              </Grid.Col>
            )}
            <Grid.Col>
              <Group>
                <Button color={props.create ? 'green' : 'orange'} type="submit">
                  {props.create
                    ? t('PackageUnitForm.CreatePackageUnit')
                    : t('PackageUnitForm.SavePackageUnit')}
                </Button>
                <Button color="gray" onClick={() => props.onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
