import React, { useState } from 'react';
import {
  createStyles,
  Group,
  Image,
  Menu,
  UnstyledButton,
} from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    maxWidth: 300,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
          ? theme.colors.gray[0]
          : theme.white,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}));

export function LanguagePicker(props: {
  selectedLanguage: string;
  setSelectedLanguage: any;
}) {
  const { t, i18n } = useTranslation();
  const data = [
    {
      label: t('UserSettings.German'),
      lang: 'Deutsch',
      image:
        'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.gb78GGBVeAQtBXGPOKX4aAHaHw%26pid%3DApi&f=1',
    },
    {
      label: t('UserSettings.English'),
      lang: 'en',
      image: 'https://icon-library.com/images/icon-english/icon-english-7.jpg',
    },
  ];
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const selectedItem = props.selectedLanguage
    ? data.find((item) => item.lang === props.selectedLanguage)
    : data[0];

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={18} height={18} />}
      onClick={() => {
        props.setSelectedLanguage(item.lang);
        i18n.changeLanguage(item.lang);
      }}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <>
      {t('main.Language')}
      <Menu
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        radius="md"
        width="target"
      >
        <Menu.Target>
          <UnstyledButton className={classes.control}>
            <Group spacing="xs">
              <Image
                src={
                  (props.selectedLanguage
                    ? data.find((item) => item.lang === props.selectedLanguage)
                    : data[0]
                  ).image
                }
                width={22}
                height={22}
              />
              <span
                className={
                  (props.selectedLanguage
                    ? data.find((item) => item.lang === props.selectedLanguage)
                    : data[0]
                  ).label
                }
              >
                {selectedItem.label}
              </span>
            </Group>
            <ChevronDown size={16} className={classes.icon} />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>{items}</Menu.Dropdown>
      </Menu>
    </>
  );
}
