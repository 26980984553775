
enum ORDER_PRIORITY_ENUM {
    ASAP = 'asap',
    ONE_HOUR = 'one_hour',
    TWO_HOURS = 'two_hours',
    THREE_HOURS = 'three_hours',
    FIVE_HOURS = 'five_hours',
    EIGHT_HOURS = 'eight_hours',
    TEN_HOURS = 'ten_hours'
}

const ORDER_PRIORITY_ENUM_VALUES =
  new Map<string, { color: string, label: string, priority: number }>([
    [ORDER_PRIORITY_ENUM.ASAP, {label: 'ASAP', color: 'red', priority: 0}],
    [ORDER_PRIORITY_ENUM.ONE_HOUR, {label: 'in 1h benötigt', color: 'orange', priority: 1}],
    [ORDER_PRIORITY_ENUM.TWO_HOURS, {label: 'in 2h benötigt', color: 'grape', priority: 2}],
    [ORDER_PRIORITY_ENUM.THREE_HOURS, {label: 'in 3h benötigt', color: 'indigo', priority: 3}],
    [ORDER_PRIORITY_ENUM.FIVE_HOURS, {label: 'in 5h benötigt', color: 'grey', priority: 4}],
    [ORDER_PRIORITY_ENUM.EIGHT_HOURS, {label: 'in 8h benötigt', color: 'grey', priority: 5}],
    [ORDER_PRIORITY_ENUM.TEN_HOURS, {label: 'in 10h benötigt', color: 'grey', priority: 6}]
  ]);

export {
    ORDER_PRIORITY_ENUM,
    ORDER_PRIORITY_ENUM_VALUES
};