import React, { useState } from 'react';
import { Button, Modal } from '@mantine/core';
import { Plus } from 'tabler-icons-react';

import { useTranslation } from 'react-i18next';
import { DeliveryForm } from './DeliveryForm';

export function DeliveryCreateDialog() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Button
        color="green"
        size="md"
        variant="light"
        leftIcon={<Plus />}
        onClick={() => setOpened(true)}
      >
        {t('DeliveryCreateDialog.NewDelivery')}
      </Button>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="lg"
        title={t('DeliveryCreateDialog.Title')}
      >
        <DeliveryForm
          create={true}
          loading={false}
          onFinish={() => setOpened(false)}
        />
      </Modal>
    </>
  );
}
