import React, { useContext } from 'react';
import { LocationContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import ILocation from '../../models/ILocation';

export function LocationElement(props: { id: number }) {
  const ctx = useContext(LocationContext) as AbstractContextType<ILocation>;
  const loc = ctx.entities.filter((l) => l.id == props.id);
  return <>{loc && loc.length == 1 && loc[0].name} </>;
}
