import axios from '../config/axios';

const AuthService = {
  login: async function (username: string, password: string): Promise<any> {
    return axios.post(`general/authentication/login`, {
      user_name: username,
      user_password: password,
    });
  },

  logout: async function (): Promise<any> {
    return axios.post(`general/authentication/logout`);
  },

  identify: async function (): Promise<any> {
    return axios.get(`general/user/identify`);
  },
};

export default AuthService;
