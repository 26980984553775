import { createContext, ReactNode } from 'react';
import { AbstractContextType, AbstractProvider } from './AbstractProvider';
import { WebSocketListener } from './WebSocketListener';
import IVehicle from '../models/IVehicle';
import VehicleService from '../services/VehicleService';
import IUser from '../models/IUser';
import UserService from '../services/UserService';
import ISection from '../models/ISection';
import SectionService from '../services/SectionService';
import ILocation from '../models/ILocation';
import LocationService from '../services/LocationService';
import IResource from '../models/IResource';
import ResourceService from '../services/ResourceService';
import IInquiry from '../models/IInquiry';
import InquiryService from '../services/InquiryService';
import IOrderPosition from '../models/IOrder';
import OrderPositionService from '../services/OrderPositionService';
import IPackageUnit from '../models/IPackageUnit';
import PackageUnitService from '../services/PackageUnitService';
import IVehicleType from '../models/IVehicleType';
import VehicleTypeService from '../services/VehicleTypeService';
import IDelivery from '../models/IDelivery';
import deliveryService from '../services/DeliveryService';
import deliveryPositionService from '../services/DeliveryPositionService';
import { IDeliveryPosition } from '../models/IDeliveryPosition';
import IStatistic from '../models/IStatistic';
import statisticService from '../services/statisticService';

export const VehicleTypeContext =
  createContext<AbstractContextType<IVehicleType> | null>(null);
export const VehicleContext =
  createContext<AbstractContextType<IVehicle> | null>(null);
export const UserContext = createContext<AbstractContextType<IUser> | null>(
  null,
);
export const SectionContext =
  createContext<AbstractContextType<ISection> | null>(null);
export const LocationContext =
  createContext<AbstractContextType<ILocation> | null>(null);
export const ResourceContext =
  createContext<AbstractContextType<IResource> | null>(null);
export const InquiryContext =
  createContext<AbstractContextType<IInquiry> | null>(null);
export const InquiryApprovalContext =
  createContext<AbstractContextType<IInquiry> | null>(null);
export const InquiryOrderContext =
  createContext<AbstractContextType<IInquiry> | null>(null);
export const OrderPositionContext =
  createContext<AbstractContextType<IOrderPosition> | null>(null);
export const PackageUnitContext =
  createContext<AbstractContextType<IPackageUnit> | null>(null);
export const DeliveryContext =
  createContext<AbstractContextType<IDelivery> | null>(null);
export const DeliveryPositionContext =
  createContext<AbstractContextType<IDeliveryPosition> | null>(null);
export const StatisticContext =
  createContext<AbstractContextType<IStatistic> | null>(null);

class VehicleTypeProvider extends AbstractProvider<IVehicleType> {}

class VehicleProvider extends AbstractProvider<IVehicle> {}

class UserProvider extends AbstractProvider<IUser> {}

class SectionProvider extends AbstractProvider<ISection> {}

class LocationProvider extends AbstractProvider<ILocation> {}

class ResourceProvider extends AbstractProvider<IResource> {}

class InquiryProvider extends AbstractProvider<IInquiry> {}

class OrderPositionProvider extends AbstractProvider<IOrderPosition> {}

class PackageUnitProvider extends AbstractProvider<IPackageUnit> {}

class DeliveryProvider extends AbstractProvider<IDelivery> {}

class DeliveryPositionProvider extends AbstractProvider<IDeliveryPosition> {}

class StatisticProvider extends AbstractProvider<IStatistic> {}

export function ContextWrapper(props: { children: ReactNode }) {
  // TODO provider at right place?
  return (
    <PackageUnitProvider
      service={PackageUnitService}
      context={PackageUnitContext}
    >
      <VehicleTypeProvider
        service={VehicleTypeService}
        context={VehicleTypeContext}
      >
        <VehicleProvider service={VehicleService} context={VehicleContext}>
          <UserProvider service={UserService} context={UserContext}>
            <SectionProvider service={SectionService} context={SectionContext}>
              <LocationProvider
                service={LocationService}
                context={LocationContext}
              >
                <ResourceProvider
                  service={ResourceService}
                  context={ResourceContext}
                >
                  <InquiryProvider
                    service={InquiryService}
                    context={InquiryContext}
                  >
                    <OrderPositionProvider
                      service={OrderPositionService}
                      context={OrderPositionContext}
                    >
                      <DeliveryProvider
                        service={deliveryService}
                        context={DeliveryContext}
                      >
                        <DeliveryPositionProvider
                          service={deliveryPositionService}
                          context={DeliveryPositionContext}
                        >
                          <StatisticProvider
                            service={statisticService}
                            context={StatisticContext}
                          >
                            <WebSocketListener>
                              {props.children}
                            </WebSocketListener>
                          </StatisticProvider>
                        </DeliveryPositionProvider>
                      </DeliveryProvider>
                    </OrderPositionProvider>
                  </InquiryProvider>
                </ResourceProvider>
              </LocationProvider>
            </SectionProvider>
          </UserProvider>
        </VehicleProvider>
      </VehicleTypeProvider>
    </PackageUnitProvider>
  );
}
