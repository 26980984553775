import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  Switch,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import SectionService from '../../services/SectionService';
import ISection from '../../models/ISection';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import IUser from '../../models/IUser';
import ILocation from '../../models/ILocation';
import { SectionContext, UserContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { ROLE_ENUM_VALUES } from '../../api_enums/ROLE_ENUM';
import { useTranslation } from 'react-i18next';
import { UserForm, UserFormRef } from '../users/UserForm';

interface SectionFormProps {
  create: boolean;
  onFinish: Function;
  section?: ISection;
  supervisor?: number | null;
}

export function SectionForm({
  create,
  onFinish,
  section,
  supervisor,
}: SectionFormProps) {
  const { t } = useTranslation();
  const sectionCtx = useContext(
    SectionContext,
  ) as AbstractContextType<ISection>;
  const userCtx = useContext(UserContext) as AbstractContextType<IUser>;
  const [visible, setVisible] = useState(false);
  const [leaderSections, setLeaderSections] = useState<
    { value: string; label: string }[]
  >([]);
  const [createUser, setCreateUser] = useState(true);
  const [opened, setOpened] = useState(false);
  const [sectionName, setSectionName] = useState(section?.name || '');
  const userFormRef = useRef<UserFormRef>(null);

  const saveOrUpdateSection = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      userFormRef.current?.resetForm();
      onFinish();
    };

    if (create) {
      if (createUser) {
        form.values.section_user =
          userFormRef.current?.getFormValues() as IUser;

        if (
          !(userFormRef.current?.getFormValues() as IUser)
            ?.radio_call_name_prefix
        ) {
          form.values.section_user.radio_call_name_prefix = 'none';
        }
        if (
          !(userFormRef.current?.getFormValues() as IUser)
            ?.radio_call_group_prefix
        ) {
          form.values.section_user.radio_call_group_prefix = 'none';
        }
      }

      SectionService.create(form.values as ISection)
        .then((res: any) => {
          if (createUser) {
            showAppNotifcation(
              true,
              t('SectionForm.SectionCreatedSuccessfully'),
              'PASSWORT: ' + res.data.pw,
              false,
            );
          } else {
            showAppNotifcation(
              true,
              t('SectionForm.SectionCreatedSuccessfully'),
            );
          }
        })
        .catch(() =>
          showAppNotifcation(false, t('SectionForm.SectionCannotCreate')),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (section) {
      SectionService.patch(section.id, form.values as ISection)
        .then(() => {
          showAppNotifcation(true, t('SectionForm.SectionSuccessfullyChanged'));
        })
        .catch(() =>
          showAppNotifcation(false, t('SectionForm.SectionCouldNotBeChanged')),
        ) // TODO logging react
        .finally(finallyHandler);
    } else {
      console.error('update needs a section object');
    }
  };

  const form = useForm({
    initialValues: {
      name: sectionName,
      number_of_employees: section?.number_of_employees || 0,
      roles: section?.roles || [],
      description: section?.description || '',
      supervised_by:
        (section?.supervised_by as ISection)?.id.toString() ||
        supervisor?.toString() ||
        null,
      led_by: (section?.led_by as IUser)?.id.toString() || '',
      located_at: (section?.located_at as ILocation)?.id.toString() || null,
      is_active: section ? section.is_active : true,
      section_user: undefined,
    },
    validate: {
      roles: (value) =>
        value.length < 1 || null ? t('SectionForm.SelectRole') : null,
      led_by: (value) =>
        value.length < 1 || null ? t('SectionForm.SectionManager') : null,
    },
  });

  useEffect(() => {
    if (!sectionCtx.loading) {
      const ls = [{ value: '', label: t('SectionForm.NoSupervisor') }];
      ls.push(
        ...sectionCtx.entities
          .filter((s) => true)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((l) => {
            return { value: l.id.toString(), label: l.name };
          }),
      );
      setLeaderSections(ls);
    }
  }, [sectionCtx.entities, sectionCtx.loading]);

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md">
        <form
          onSubmit={form.onSubmit(() => {
            create && createUser
              ? userFormRef.current?.validateForm() && saveOrUpdateSection()
              : saveOrUpdateSection();
          })}
        >
          <Grid>
            <Grid.Col xs={7}>
              <TextInput
                placeholder="Name"
                label="Name"
                required
                value={sectionName}
                onChange={(event) => {
                  setSectionName(event.currentTarget.value);
                  form.setValues({ name: event.currentTarget.value });
                }}
              />
            </Grid.Col>
            <Grid.Col xs={5}>
              <TextInput
                placeholder={t('SectionForm.EmployeeNumber')}
                label={t('SectionForm.EmployeeNumber')}
                {...form.getInputProps('number_of_employees')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <MultiSelect
                label={t('SectionForm.Role')}
                placeholder={t('SectionForm.SelectRole')}
                required
                data={Array.from(ROLE_ENUM_VALUES).map((k) => {
                  return { value: k[0], label: k[1].label, color: k[1].color };
                })}
                {...form.getInputProps('roles')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={t('SectionForm.Superior')}
                placeholder={t('SectionForm.SelectSuperior')}
                data={leaderSections}
                {...form.getInputProps('supervised_by')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                placeholder={t('SectionForm.SectionManager')}
                label={t('SectionForm.SectionManager')}
                data={userCtx.entities.map((u) => {
                  return { value: u.id.toString(), label: u.name };
                })}
                required
                {...form.getInputProps('led_by')}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Textarea
                placeholder={t('SectionForm.Description')}
                label={t('SectionForm.Description')}
                {...form.getInputProps('description')}
              />
            </Grid.Col>
            {create && (
              <Grid.Col xs={12}>
                <Switch
                  color="lime"
                  label={t('SectionForm.CreateUsersAutomatically')}
                  checked={createUser}
                  onChange={(event) => {
                    setCreateUser(event.currentTarget.checked);
                  }}
                />
              </Grid.Col>
            )}
            {!create && (
              <Grid.Col xs={4}>
                <Switch
                  color="lime"
                  label={t('SectionForm.Active')}
                  {...form.getInputProps('is_active', { type: 'checkbox' })}
                />
              </Grid.Col>
            )}
            {create && createUser && (
              <UserForm
                ref={userFormRef}
                create={true}
                onFinish={() => setOpened(false)}
                sectionCreate={true}
                sectionName={sectionName}
              />
            )}
            <Grid.Col>
              <Group>
                <Button color={create ? 'green' : 'orange'} type="submit">
                  {create
                    ? t('SectionForm.CreateSection')
                    : t('SectionForm.SaveSection')}
                </Button>
                <Button color="gray" onClick={() => onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
