import React from 'react';
import { Badge } from '@mantine/core';
import { VEHICLE_STATUS_ENUM_VALUES } from '../../api_enums/VEHICLE_STATUS_ENUM';

export function VehicleStatusElement(props: { status: string }) {
  const statusDetails = VEHICLE_STATUS_ENUM_VALUES.get(props.status);
  if (statusDetails) {
    const color = statusDetails.color;
    return (
      <Badge
        sx={{ paddingLeft: 5, paddingTop: 5, paddingBottom: 2 }}
        size="lg"
        radius="md"
        color={color}
      >
        {statusDetails.label}
      </Badge>
    );
  } else {
    console.warn('unknown status');
    return <>{props.status}</>;
  }
}
