import { ReactNode, useContext, useMemo } from 'react';

import { AbstractContextType } from './AbstractProvider';
import {
  DeliveryContext,
  InquiryContext,
  LocationContext,
  OrderPositionContext,
  PackageUnitContext,
  ResourceContext,
  SectionContext,
  StatisticContext,
  UserContext,
  VehicleContext,
  VehicleTypeContext,
} from './ContextWrapper';
import ILocation from '../models/ILocation';
import IVehicle from '../models/IVehicle';
import ISection from '../models/ISection';
import IUser from '../models/IUser';
import IResource from '../models/IResource';
import IInquiry from '../models/IInquiry';
import IOrderPosition from '../models/IOrder';
import IVehicleType from '../models/IVehicleType';
import IStatistic from '../models/IStatistic';
import IPackageUnit from '../models/IPackageUnit';
import IDelivery from '../models/IDelivery';

import useWebSocket from 'react-use-websocket';

export function WebSocketListener(props: { children: ReactNode }) {
  const contextMap = useMemo(
    () => new Map<string, AbstractContextType<any>>(),
    [],
  );

  contextMap.set(
    'resource',
    useContext(ResourceContext) as AbstractContextType<IResource>,
  );
  contextMap.set(
    'location',
    useContext(LocationContext) as AbstractContextType<ILocation>,
  );
  contextMap.set(
    'vehicle',
    useContext(VehicleContext) as AbstractContextType<IVehicle>,
  );
  contextMap.set(
    'vehicle_type',
    useContext(VehicleTypeContext) as AbstractContextType<IVehicleType>,
  );
  contextMap.set('user', useContext(UserContext) as AbstractContextType<IUser>);
  contextMap.set(
    'section',
    useContext(SectionContext) as AbstractContextType<ISection>,
  );
  contextMap.set(
    'inquiry',
    useContext(InquiryContext) as AbstractContextType<IInquiry>,
  );
  contextMap.set(
    'order-position',
    useContext(OrderPositionContext) as AbstractContextType<IOrderPosition>,
  );
  contextMap.set(
    'statistics',
    useContext(StatisticContext) as AbstractContextType<IStatistic>,
  );
  contextMap.set('user', useContext(UserContext) as AbstractContextType<IUser>);
  contextMap.set(
    'package-unit',
    useContext(PackageUnitContext) as AbstractContextType<IPackageUnit>,
  );
  contextMap.set(
    'delivery',
    useContext(DeliveryContext) as AbstractContextType<IDelivery>,
  );

  useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
    onOpen: () => console.log('WebSocket connection opened!'),
    onClose: () => console.log('WebSocket connection closed!'),
    onError: (event: any) => console.error('WebSocket error:', event),
    onMessage: (event: any) => {
      const msg = JSON.parse(event.data);

      if (msg.type === 'ARCHIVE') {
        // TODO: check if refreshing all the data could be integrated in the contexts components.
        (() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })();
        return;
      }
      // websockets only provide a string as payload, Json parse cannot detect Dates which are required by models and the app to work properly
      // TODO optimal solution? preformance maybe different date formats? can websocket provide object instead of strings?
      parseDateAttributes(msg);

      const ctx = contextMap.get(msg.path);
      if (!ctx) {
        console.error(`no context available for websocket path '${msg.path}'`);
        return;
      }
      if (msg.type === 'CREATE') {
        ctx.add(msg.payload);
      } else if (msg.type === 'UPDATE') {
        ctx.update(msg.payload);
      } else if (msg.type === 'DELETE') {
        ctx.remove(msg.payload.id);
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  // expected datetime format "2022-10-13T14:44:24.004Z"
  function parseDateAttributes(o: any) {
    for (let a in o) {
      if (
        typeof o[a] === 'string' &&
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(o[a])
      ) {
        o[a] = new Date(o[a]);
      } else if (typeof o[a] === 'object') {
        parseDateAttributes(o[a]);
      }
    }
  }

  return <> {props.children} </>;
}
