import {PACKAGE_UNIT_ENUM} from "./PACKAGE_UNIT_ENUM";


enum RESOURCE_TYPE_ENUM {
    MATERIAL = 'material',
    EQUIPMENT = 'equipment',
    MANPOWER ='manpower'
}

const RESOURCE_TYPE_ENUM_VALUES = new Map<string, {label: string}>([
    [RESOURCE_TYPE_ENUM.MATERIAL, {label: 'Material'}],
    [RESOURCE_TYPE_ENUM.EQUIPMENT, {label: 'Ausrüstung'}],
    [RESOURCE_TYPE_ENUM.MANPOWER, {label: 'Mannschaft'}]
]);

export {
    RESOURCE_TYPE_ENUM,
    RESOURCE_TYPE_ENUM_VALUES
}
