import AbstractCRUDService from './AbstractCRUDService';
import { AxiosResponse } from 'axios';
import axios from '../config/axios';
import IOrderPosition from '../models/IOrder';
import { IDeliveryPosition } from '../models/IDeliveryPosition';
import IDelivery from '../models/IDelivery';

class OrderPositionService extends AbstractCRUDService<IOrderPosition> {
  shipBasePath = 'shipping/delivery';

  async ship(
    delivery: IDelivery,
    delivery_id: number,
    deliveryOptionsList: IDeliveryPosition[],
  ): Promise<AxiosResponse<IOrderPosition>> {
    const obj = {
      delivery,
      delivery_id: delivery_id,
      deliveryOptionsList: deliveryOptionsList,
    };
    return axios.patch(`${this.shipBasePath}/ship`, obj);
  }

  async load(
    objIds: number[],
    delivery_id: number,
    shipped_amount: number,
  ): Promise<AxiosResponse<IOrderPosition>> {
    const obj = {
      delivery_id: delivery_id,
      order_position_ids: objIds,
      shipped_amount: shipped_amount,
    };
    return axios.post(`${this.shipBasePath}/load`, obj);
  }
}

export default new OrderPositionService('purchasing/order');
