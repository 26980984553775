import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  SectionContext,
  VehicleContext,
} from '../../../../context/ContextWrapper';
import { AbstractContextType } from '../../../../context/AbstractProvider';
import IVehicle from '../../../../models/IVehicle';
import IOrderPosition, {
  getPrefixedOrderPositionId,
} from '../../../../models/IOrder';
import { VEHICLE_STATUS_ENUM_VALUES } from '../../../../api_enums/VEHICLE_STATUS_ENUM';
import IPackageUnit from '../../../../models/IPackageUnit';
import { showAppNotifcation } from '../../../../utility/NotificationConfigs';
import { useForm } from '@mantine/form';
import IDelivery from '../../../../models/IDelivery';
import IInquiry, { getPrefixedInquiryId } from '../../../../models/IInquiry';
import DeliveryService from '../../../../services/DeliveryService';
import {
  getPrefixedDeliveryPositionId,
  IDeliveryPosition,
} from '../../../../models/IDeliveryPosition';
import { DeliveryPositionDeleteDialog } from './DeliveryPositionDeleteDialog';
import ILocation from '../../../../models/ILocation';
import { InquiryPriorityElement } from '../../InquiryPriorityElement';
import { DeliveryStatusElement } from '../../DeliveryStatusElement';
import OrderPositionService from '../../../../services/OrderPositionService';
import { DELIVERY_STATUS_ENUM } from '../../../../api_enums/DELIVERY_STATUS_ENUM';
import { useInputState } from '@mantine/hooks';
import { AlertCircle } from 'tabler-icons-react';
import IVehicleType from '../../../../models/IVehicleType';
import ISection from '../../../../models/ISection';
import { AuthContext, AuthContextType } from '../../../../context/AuthProvider';
import { ROLE_ENUM } from '../../../../api_enums/ROLE_ENUM';
import { sortVehicle } from '../../../../utility/sorter';

function showAlert(visibility: any, msg: string) {
  return (
    <Alert icon={<AlertCircle size={16} />} color="red" display={visibility}>
      {msg}
    </Alert>
  );
}

const getLoadAmount = (elements: IDeliveryPosition[]) => {
  let sum_shipped_amount = 0;
  if (elements) {
    elements.forEach((e) => {
      sum_shipped_amount += e.shipped_amount;
    });
  }

  return sum_shipped_amount;
};

function createRows(
  elements: IDeliveryPosition[],
  setDeliveryOptionsList: any,
  setSumShippedAmount: any,
  loadingAmount: number,
  loading: boolean,
) {
  return elements
    .sort((a, b) =>
      (
        ((a.ordered_through as IOrderPosition)?.inquired_through as IInquiry)
          ?.deliver_to as ILocation
      )?.name.localeCompare(
        (
          ((b.ordered_through as IOrderPosition)?.inquired_through as IInquiry)
            ?.deliver_to as ILocation
        )?.name,
      ),
    )
    .map((element) => {
      const max_load = (element.ordered_through as IOrderPosition)?.max_loading;
      let remaining_amount = (element.ordered_through as IOrderPosition)
        ?.remaining_amount;
      let shipped_amount = element?.shipped_amount;

      return (
        <tr key={element.id}>
          <td>
            {getPrefixedInquiryId(
              (
                (element.ordered_through as IOrderPosition)
                  .inquired_through as IInquiry
              )?.id,
            )}
          </td>
          <td>{getPrefixedDeliveryPositionId(element.id)}</td>
          <td>
            {getPrefixedOrderPositionId(
              (element.ordered_through as IOrderPosition)?.id,
            )}
          </td>
          <td>
            <DeliveryStatusElement status={element.status} />
          </td>
          <td>
            <InquiryPriorityElement value={element.priority} />
          </td>
          <td>
            {
              (
                (
                  (element.ordered_through as IOrderPosition)
                    ?.inquired_through as IInquiry
                )?.deliver_to as ILocation
              )?.name
            }
          </td>
          <td>{(element.ordered_through as IOrderPosition)?.ordered_amount}</td>
          <td>{(element.ordered_through as IOrderPosition)?.remain_loading}</td>
          <td>
            {!loading && (
              <NumberInput
                style={{ width: 80 }}
                defaultValue={remaining_amount}
                min={1}
                max={max_load}
                value={shipped_amount}
                onChange={(v) => {
                  shipped_amount = v;
                  elements.find(
                    (item) => item.id === element.id,
                  ).shipped_amount = v;
                  setDeliveryOptionsList(elements);
                  setSumShippedAmount(
                    Number(loadingAmount) + getLoadAmount(elements),
                  );
                }}
                required
              />
            )}
            {loading && shipped_amount}
          </td>
          <td hidden={loading}>
            <Group spacing="xs">
              <DeliveryPositionDeleteDialog deliveryPosition={element} />
            </Group>
          </td>
        </tr>
      );
    });
}

interface IDeliveryProps {
  create: boolean;
  loading: boolean;
  onFinish: Function;
  delivery?: IDelivery;
  loadingAmount?: number;
}

export function DeliveryForm({
  create,
  loading,
  onFinish,
  delivery,
  loadingAmount = 0,
}: IDeliveryProps) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [opened, setOpened] = useState(false);
  const [vehicle, setVehicle] = useState<string | null>(null);
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const vehicleCtx = useContext(
    VehicleContext,
  ) as AbstractContextType<IVehicle>;
  const sectionCtx = useContext(
    SectionContext,
  ) as AbstractContextType<ISection>;
  const [deliveryOptionsList, setDeliveryOptionsList] = useInputState(
    delivery?.positions as unknown as IDeliveryPosition[],
  );

  const [sumShippedAmount, setSumShippedAmount] = useState(
    Number(loadingAmount)
      ? Number(loadingAmount) +
          getLoadAmount(delivery?.positions as unknown as IDeliveryPosition[])
      : getLoadAmount(delivery?.positions as unknown as IDeliveryPosition[]),
  );

  const showLoadingCapacityAlert = (loadAmount: number) => {
    let sumLoadingAmount = Number(loadingAmount) + loadAmount;
    return sumLoadingAmount >
      (delivery?.delivered_through as IVehicle)?.max_volume
      ? showAlert('', t('DeliveryForm.LoadingCapacityAlert'))
      : showAlert('none', t('DeliveryForm.LoadingCapacityAlert'));
  };

  const checkPermission = () => {
    return (
      userInfo.section_roles.includes(ROLE_ENUM.MANAGER) ||
      userInfo.section_roles.includes(ROLE_ENUM.ADMINISTRATOR)
    );
  };

  const createDelivery = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      onFinish();
    };

    if (create) {
      if (!checkPermission()) {
        form.values.assigned_to = userInfo.section_id.toString();
      }
      DeliveryService.create(form.values as IDelivery)
        .then(() => {
          showAppNotifcation(true, t('DeliveryForm.DeliveryCreate'));
        })
        .catch(() =>
          showAppNotifcation(false, t('DeliveryForm.DeliveryCanNotCreate')),
        )
        .finally(finallyHandler);
    } else {
      let sum_shipped_amount = 0;
      deliveryOptionsList.forEach((d) => {
        if (d.shipped_amount == 0) {
          d.shipped_amount = (
            d.ordered_through as IOrderPosition
          ).remaining_amount;
        }
        sum_shipped_amount += (d.ordered_through as IOrderPosition)
          .remaining_amount;
      });
      if (deliveryOptionsList.length > 0) {
        OrderPositionService.ship(
          form.values as IDelivery,
          delivery?.id,
          deliveryOptionsList,
        )
          .then(() => {
            showAppNotifcation(true, t('DeliveryForm.DeliveryShip'));
          })
          .catch(() =>
            showAppNotifcation(false, t('DeliveryForm.DeliveryCanNotShip')),
          )
          .finally(finallyHandler);
      }
    }
  };

  const form = useForm({
    initialValues: {
      delivered_through:
        (delivery?.delivered_through as IVehicle)?.id.toString() || '',
      assigned_to: (delivery?.assigned_to as ISection)?.id.toString() || '',
    },
    validate: {
      delivered_through: (value) =>
        value == null ? t('InquiryForm.SelectDeliverTo') : null,
      assigned_to: (value) =>
        checkPermission() &&
        (value.length < 1 || null ? t('main.SelectOperationSection') : null),
    },
  });
  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      {!create &&
        showLoadingCapacityAlert(
          getLoadAmount(delivery?.positions as unknown as IDeliveryPosition[]),
        )}
      {!loading && (
        <form onSubmit={form.onSubmit(() => createDelivery())}>
          <Grid>
            <Grid.Col xs={12}>
              <Select
                label={t('main.Vehicle')}
                placeholder={t('DeliveryForm.SelectVehicle')}
                required
                searchable
                disabled={
                  !create && delivery?.status !== DELIVERY_STATUS_ENUM.PICKING
                }
                data={vehicleCtx.entities
                  .sort((a, b) => sortVehicle(a, b))
                  .map((s) => {
                    return {
                      value: s.id.toString(),
                      label:
                        s.radio_call_name +
                        ', ' +
                        (s.type_of as IVehicleType)?.name +
                        (s.license_plate ? ', ' + s.license_plate : '') +
                        ', ' +
                        s.max_cargo +
                        ' ' +
                        (s.package_unit as IPackageUnit).name +
                        ', ' +
                        VEHICLE_STATUS_ENUM_VALUES.get(s.status)?.label,
                    };
                  })}
                {...form.getInputProps('delivered_through')}
              />
            </Grid.Col>
            {create && checkPermission() && (
              <Grid.Col xs={12}>
                <Select
                  label={t('UserTable.MissionSection')}
                  placeholder={t('UserTable.MissionSection')}
                  required
                  data={sectionCtx.entities
                    .filter((s) => s.roles.includes(ROLE_ENUM.SUPPLIER))
                    .map((s) => {
                      return {
                        value: s.id.toString(),
                        label: s.name + ' ' + s.description,
                      };
                    })}
                  {...form.getInputProps('assigned_to')}
                />
              </Grid.Col>
            )}
            <Grid.Col>
              <Group>
                <Button
                  color={'green'}
                  type="submit"
                  disabled={
                    create
                      ? false
                      : (delivery?.positions as unknown as IDeliveryPosition[])
                          ?.length === 0
                  }
                >
                  {create
                    ? t('DeliveryForm.CreateDelivery')
                    : t('DeliveryForm.SendDelivery')}
                </Button>
                <Button color="gray" onClick={() => onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      )}
      {!create && (
        <>
          <Divider my="xl" />
          <ScrollArea h={'auto'}>
            <Table striped verticalSpacing="xs" fontSize="md">
              <thead>
                <tr>
                  <th>{t('main.RequirementNo')}</th>
                  <th>{t('main.DeliveryPositionNo')}</th>
                  <th>{t('main.OrderPositionNo')}</th>
                  <th>{t('main.Status')}</th>
                  <th>{t('main.Priority')}</th>
                  <th>{t('main.DeliveryLocation')}</th>
                  <th>{t('main.Quantity')}</th>
                  <th>{t('DeliveryForm.Remaining')}</th>
                  <th>{t('DeliveryForm.Loaded')}</th>
                  <th hidden={loading}>{t('main.Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {delivery?.positions &&
                  createRows(
                    delivery?.positions as unknown as IDeliveryPosition[],
                    setDeliveryOptionsList,
                    setSumShippedAmount,
                    loadingAmount,
                    loading,
                  )}
                {!delivery?.positions &&
                  [1, 2].map((id) => {
                    return (
                      <tr key={id}>
                        <td>
                          <Skeleton
                            height={12}
                            mt={6}
                            width="20%"
                            radius="xl"
                          />
                        </td>
                        <td>
                          <Skeleton
                            height={12}
                            mt={6}
                            width="30%"
                            radius="xl"
                          />
                        </td>
                        <td>
                          <Skeleton
                            height={12}
                            mt={6}
                            width="10%"
                            radius="xl"
                          />
                        </td>
                        <td>
                          <Skeleton
                            height={12}
                            mt={6}
                            width="20%"
                            radius="xl"
                          />
                        </td>
                        <td>
                          <Skeleton
                            height={12}
                            mt={6}
                            width="10%"
                            radius="xl"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {delivery?.positions && delivery?.positions.length === 0 && (
              <Text align="center">{t('DeliveryForm.NoDelivery')}</Text>
            )}
          </ScrollArea>
        </>
      )}
    </div>
  );
}
