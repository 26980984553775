import { Component, Context, ReactNode } from 'react';
import { AxiosResponse } from 'axios';

export type AbstractContextType<E> = {
  entities: E[];
  loading: boolean;
  lastUpdate: Date | null;
  add: (bin: E) => void;
  update: (e: E) => void;
  remove: (id: number) => void;
};

interface ProviderProps<E> {
  service: { findAll: () => Promise<AxiosResponse<E[]>> };
  context: Context<AbstractContextType<E> | null>;
  children: ReactNode;
}

interface ProviderState<E> {
  entities: E[];
  loading: boolean;
  lastUpdate: Date | null;
  add: (bin: E) => void;
  update: (e: E) => void;
  remove: (id: number) => void;
}

interface Identifiable {
  id: number;
}

export abstract class AbstractProvider<
  E extends Identifiable,
> extends Component<ProviderProps<E>, ProviderState<E>> {
  constructor(props: ProviderProps<E>) {
    super(props);
    const idEqualsAndValuesDifferent = (e1: E, e2: E) => {
      const k1 = Object.keys(e1);

      if (e1.id !== e2.id) return false;
      else
        return k1
          .filter((k) => k !== 'id')
          .reduce((p: boolean, c: string) => {
            // @ts-ignore
            return p || e1[c] !== e2[c];
          }, false);
    };
    this.state = {
      entities: [],
      loading: true,
      lastUpdate: null,
      add: (e: E) => {
        if (this.state.entities.filter((w) => w.id === e.id).length === 0) {
          this.setState({ entities: [...this.state.entities, e] });
        }
      },
      update: (e: E) => {
        this.state.entities.filter((w: E) => {
          if (idEqualsAndValuesDifferent(w, e)) {
            Object.assign(w, e);
            this.setState({
              entities: [...this.state.entities],
              lastUpdate: new Date(),
            });
          }
        });
      },
      remove: (id: number) => {
        if (this.state.entities.filter((b) => b.id === id).length === 0) {
          return;
        }
        this.setState({
          entities: [...this.state.entities.filter((b) => b.id !== id)],
        });
      },
    };
  }

  componentDidMount() {
    this.props.service
      .findAll()
      .then((result) => {
        this.setState({ entities: result.data });
      })
      .catch((e) => console.log(e))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { entities, loading, lastUpdate, add, update, remove } = this.state;
    const Context = this.props.context;
    return Context ? (
      <Context.Provider
        value={{ entities, loading, lastUpdate, add, update, remove }}
      >
        {this.props.children}
      </Context.Provider>
    ) : (
      <>{this.props.children}</>
    );
  }
}
