import IResource from './IResource';
import IUser from './IUser';
import ILocation from './ILocation';
import ISection from './ISection';
import IHistoryEntry from './IHistoryEntry';

export default interface IInquiry {
  id: number;
  status: string;
  history: IHistoryEntry[];
  priority: string;
  amount: number;
  remain_loading: number;
  created_at: Date;
  inquired_by: Partial<IUser> | string;
  deliver_to: Partial<ILocation> | string;
  inquired_is: Partial<IResource> | string;
  is_waiting_at: Partial<ISection> | string;
  ordered_from: Partial<ISection> | string;
  requester: string;
  requester_info: string;
  recipient: string;
  recipient_info: string;
  comment: string;
}

export function getPrefixedInquiryId(id: number) {
  return 'BA-' + id;
}
