import { createContext, ReactNode, useState } from 'react';
import AuthService from '../services/AuthService';
import { ROLE_ENUM } from '../api_enums/ROLE_ENUM';
import IOptions from '../models/IOptions';

/*
* TODO

* CASL for fine grained user control
* or Wrapper component checking roles and returning the element or null
* render <Route> based on user rolen and hide links
* */

export const AuthContext = createContext<AuthContextType | null>(null);

export type AuthContextType = {
  userInfo: IUserInfo | null;
  getUser: () => IUserInfo | null;
  getOptions: () => IOptions | null;
  setOptions: (options: IOptions) => void;
  login: (
    username: string,
    password: string,
    stayLoggedIn: boolean,
  ) => Promise<any>;
  logout: () => void;
  isLoggedIn: () => boolean;
  hasRight: (roles: ROLE_ENUM[]) => boolean;
};

export interface IUserInfo {
  section_id: number;
  section_leader_id: number;
  section_name: string;
  section_desc: string;
  section_roles: string[];
  section_supervisor_id: number;
  user_id: number;
  user_name: string;
  user_roles: string[];
  user_options: IOptions;
  user_forename: string;
  user_surname: string;
}

function parseJwt(token: string): IUserInfo {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const AuthProvider = (props: { children: ReactNode }) => {
  const lsUserInfoKey = 'userInfo';
  const lsJwtKey = 'jwt';
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);

  if (userInfo === null) {
    const userdata = localStorage.getItem('userdata');
    const user = userdata ? JSON.parse(userdata) : null;
    if (user !== null) {
      setUserInfo(user);
    }
  }

  const login = (
    username: string,
    password: string,
    stayLoggedIn: boolean,
  ): Promise<any> => {
    return new Promise(function (resolve, reject) {
      AuthService.login(username, password)
        .then((res) => {
          const tokenUI = parseJwt(res.data.token);
          setUserInfo(tokenUI);
          // TODO not stay logged in does not work because localstorage is read for jwt
          if (stayLoggedIn) {
            localStorage.setItem(
              lsUserInfoKey,
              JSON.stringify(res.data as IUserInfo),
            );
            localStorage.setItem(lsJwtKey, res.data.token);
            localStorage.setItem('userdata', JSON.stringify(tokenUI));
          }
          resolve(true);
        })
        .catch((err) => {
          console.log('ERROR: ' + err);
          reject(err);
        });
    });
  };

  const logout = () => {
    setUserInfo(() => null);
    localStorage.removeItem(lsUserInfoKey);
    localStorage.removeItem(lsJwtKey);
    localStorage.removeItem('userdata');
    AuthService.logout();
    window.location.reload();
  };

  const getUser = (): IUserInfo | null => {
    return userInfo;
  };

  const getOptions = (): IOptions | null => {
    return userInfo ? userInfo.user_options : null;
  };

  const setOptions = (options: IOptions): void => {
    const user = { ...userInfo };
    user.user_options = options;
    localStorage.setItem('userdata', JSON.stringify(user));
    setUserInfo(user);
  };

  const isLoggedIn = (): boolean => {
    if (!userInfo && localStorage.getItem(lsUserInfoKey)) {
      return true;
    } else {
      return userInfo != null;
    }
  };

  const hasRight = (roles: ROLE_ENUM[]): boolean => {
    const user = getUser();
    if (user && user.user_roles && user.section_roles) {
      const isAdmin =
        user.user_roles.includes(ROLE_ENUM.ADMINISTRATOR) ||
        user.section_roles.includes(ROLE_ENUM.ADMINISTRATOR);
      const isManager =
        user.user_roles.includes(ROLE_ENUM.MANAGER) ||
        user.section_roles.includes(ROLE_ENUM.MANAGER);
      const hasUserRole = roles
        .map((r) => {
          return user.user_roles.includes(r);
        })
        .reduce((a, b) => a || b);
      const hasSectionRole = roles
        .map((r) => {
          return user.section_roles.includes(r);
        })
        .reduce((a, b) => a || b);
      return isAdmin || hasUserRole || hasSectionRole || isManager;
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        login,
        logout,
        getUser,
        getOptions,
        setOptions,
        isLoggedIn,
        hasRight,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
