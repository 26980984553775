import { Check, X } from 'tabler-icons-react';
import React from 'react';
import { showNotification } from '@mantine/notifications';

export function showAppNotifcation(
  success: boolean,
  title: string,
  message: string = '',
  autoClose: number | boolean = 5000,
) {
  showNotification({
    icon: success ? <Check size={18} /> : <X size={18} />,
    color: success ? 'teal' : 'red',
    title: title,
    message: message,
    autoClose: autoClose,
  });
}
