import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { showAppNotifcation } from '../../../../utility/NotificationConfigs';
import { DeliveryPositionContext } from '../../../../context/ContextWrapper';
import { AbstractContextType } from '../../../../context/AbstractProvider';
import DeliveryPositionService from '../../../../services/DeliveryPositionService';
import { useTranslation } from 'react-i18next';
import {
  getPrefixedDeliveryPositionId,
  IDeliveryPosition,
} from '../../../../models/IDeliveryPosition';

export function DeliveryPositionDeleteDialog(props: {
  deliveryPosition: IDeliveryPosition;
}) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(
    DeliveryPositionContext,
  ) as AbstractContextType<IDeliveryPosition>;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('DeliveryPositionDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('DeliveryPositionDeleteDialog.DeleteConfirm', {
                name: getPrefixedDeliveryPositionId(props.deliveryPosition?.id),
              })}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  DeliveryPositionService.remove(props.deliveryPosition?.id)
                    .then(() => {
                      ctx.remove(props.deliveryPosition?.id);
                      showAppNotifcation(
                        true,
                        t(
                          'DeliveryPositionDeleteDialog.OrdersDeletedSuccessfully',
                        ),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('DeliveryPositionDeleteDialog.OrdersCannotDelete'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Delete')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
