enum VEHICLE_STATUS_ENUM {
  WILLBESUPPLIED = 'will_be_supplied',
  IDLE = 'idle',
  WAITING = 'waiting',
  LOADING = 'loading',
  SHIPPING = 'shipping',
  WILLBEDEDUCTED = 'will_be_deducted',
  NEEDEDELSEWHERE = 'needed_elsewhere',
  DRIVERLESS = 'driverless',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INREPAIR = 'in_repair',
  BROKEN = 'broken',
}

const VEHICLE_STATUS_ENUM_VALUES = new Map<
  string,
  { label: string; color: string }
>([
  [
    VEHICLE_STATUS_ENUM.WILLBESUPPLIED,
    { label: 'Wird bereitgestellt', color: 'green' },
  ],
  [VEHICLE_STATUS_ENUM.IDLE, { label: 'Untätig', color: 'green' }],
  [VEHICLE_STATUS_ENUM.WAITING, { label: 'Wartend', color: 'green' }],
  [VEHICLE_STATUS_ENUM.LOADING, { label: 'Wird beladen', color: 'orange' }],
  [VEHICLE_STATUS_ENUM.SHIPPING, { label: 'Liefert aus', color: 'red' }],
  [
    VEHICLE_STATUS_ENUM.WILLBEDEDUCTED,
    { label: 'Wird abgezogen', color: 'green' },
  ],
  [
    VEHICLE_STATUS_ENUM.NEEDEDELSEWHERE,
    { label: 'Feuerwehrauto', color: 'green' },
  ],
  [VEHICLE_STATUS_ENUM.DRIVERLESS, { label: 'Fahrerlos', color: 'green' }],
  [VEHICLE_STATUS_ENUM.ACTIVE, { label: 'Aktiv', color: 'green' }],
  [VEHICLE_STATUS_ENUM.INACTIVE, { label: 'Inaktiv', color: 'green' }],
  [VEHICLE_STATUS_ENUM.INREPAIR, { label: 'In Reparatur', color: 'green' }],
  [VEHICLE_STATUS_ENUM.BROKEN, { label: 'Defekt', color: 'green' }],
]);

export { VEHICLE_STATUS_ENUM, VEHICLE_STATUS_ENUM_VALUES };
