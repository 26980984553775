import React, { useContext, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import LocationService from '../../services/LocationService';
import ILocation from '../../models/ILocation';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import ISection from '../../models/ISection';
import { useTranslation } from 'react-i18next';
import { SectionContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';

export function LocationForm(props: {
  create: boolean;
  onFinish: Function;
  location?: ILocation;
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const ctx = useContext(SectionContext) as AbstractContextType<ISection>;

  const filterSection = (value: ISection) => {
    if (userInfo.section_supervisor_id) {
      return (
        (value.id === userInfo.section_id ||
          (value.supervised_by as ISection)?.id === userInfo.section_id) &&
        value.is_active === true
      );
    } else {
      return value.is_active === true;
    }
  };

  const saveOrUpdateLocation = () => {
    setVisible(true);

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      props.onFinish();
    };

    if (props.create) {
      // TODO Geolocation should be sleected
      LocationService.create(form.values as ILocation)
        .then(() => {
          showAppNotifcation(true, t('LocationForm.DeliveryLocationCreated'));
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('LocationForm.DeliveryLocationCanNotCreate'),
          ),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (!props.create && props.location) {
      LocationService.patch(props.location.id, form.values as ILocation)
        .then(() => {
          showAppNotifcation(true, t('LocationForm.DeliveryLocationChanged'));
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('LocationForm.DeliveryLocationCanNotChange'),
          ),
        ) // TODO logging react
        .finally(finallyHandler);
    } else {
      console.error('update needs a location object');
    }
  };

  const form = useForm({
    initialValues: {
      name: props.location?.name || '',
      street_name: props.location?.street_name || '',
      house_number: props.location?.house_number || '',
      postal_code: props.location?.postal_code || '',
      city: props.location?.city || '',
      belongs_to: (props.location?.belongs_to as ISection)?.id.toString() || '',
    },
    validate: {
      belongs_to: (value) =>
        value.length < 1 || null ? t('main.SelectOperationSection') : null,
    },
  });

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md">
        <form onSubmit={form.onSubmit(() => saveOrUpdateLocation())}>
          <Grid>
            <Grid.Col xs={8}>
              <TextInput
                placeholder={t('LocationForm.Name')}
                label={t('LocationForm.Name')}
                required
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <Select
                label={t('UserTable.MissionSection')}
                placeholder={t('UserTable.MissionSection')}
                required
                data={ctx.entities.filter(filterSection).map((s) => {
                  return { value: s.id.toString(), label: s.name };
                })}
                {...form.getInputProps('belongs_to')}
              />
            </Grid.Col>
            <Grid.Col xs={8}>
              <TextInput
                placeholder={t('main.Street')}
                label={t('main.Street')}
                {...form.getInputProps('street_name')}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <TextInput
                placeholder="1a"
                label={t('main.HouseNumber')}
                {...form.getInputProps('house_number')}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <TextInput
                placeholder="94032"
                label={t('main.PostalCode')}
                {...form.getInputProps('postal_code')}
              />
            </Grid.Col>
            <Grid.Col xs={8}>
              <TextInput
                placeholder="Passau"
                label={t('main.City')}
                {...form.getInputProps('city')}
              />
            </Grid.Col>
            <Grid.Col>
              <Group>
                <Button color={props.create ? 'green' : 'orange'} type="submit">
                  {props.create
                    ? t('LocationForm.CreateDeliveryLocation')
                    : t('LocationForm.SaveDeliveryLocation')}
                </Button>
                <Button color="gray" onClick={() => props.onFinish()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
