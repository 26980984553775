import IStatistic from '../models/IStatistic';
import AbstractCRUDService from './AbstractCRUDService';
import { AxiosResponse } from 'axios';
import axios from '../config/axios';

class StatisticService extends AbstractCRUDService<IStatistic> {
  override async findAll(): Promise<AxiosResponse<IStatistic[]>> {
    return axios.get<IStatistic[]>(`${this.basePath}/find/all`);
  }
}

export default new StatisticService('statistics/statistic');
