import React from 'react';
import { INQUIRY_STATUS_ENUM_VALUES } from '../../api_enums/INQUIRY_STATUS_ENUM';
import { Badge } from '@mantine/core';

export function InquiryStatusElement(props: { status: string }) {
  const statusDetails = INQUIRY_STATUS_ENUM_VALUES.get(props.status);
  if (statusDetails) {
    const Icon = statusDetails.icon;
    const color = statusDetails.color;
    return (
      <Badge
        sx={{ paddingLeft: 5, paddingTop: 5, paddingBottom: 2 }}
        size="lg"
        radius="md"
        color={color}
        leftSection={<Icon size={12} />}
      >
        {statusDetails.label}
      </Badge>
    );
  } else {
    console.warn('unknown status');
    return <>{props.status}</>;
  }
}
