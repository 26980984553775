import React, { useContext, useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';

import { showAppNotifcation } from '../../utility/NotificationConfigs';
import IUser from '../../models/IUser';
import UserService from '../../services/UserService';
import { UserContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';

export function UserDeleteDialog(props: { user: IUser }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ctx = useContext(UserContext) as AbstractContextType<IUser>;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('UserDeleteDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('UserDeleteDialog.SureDeleteUser', { name: props.user.name })}
            </Text>
            <Group>
              <Button
                color="red"
                onClick={() => {
                  UserService.remove(props.user.id)
                    .then(() => {
                      ctx.remove(props.user.id);
                      showAppNotifcation(
                        true,
                        t('UserDeleteDialog.UserDeletedSuccessfully'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('UserDeleteDialog.UserCouldNotBeDeleted'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('main.Delete')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        color="red"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Delete')}
      >
        <Trash />
      </ActionIcon>
    </>
  );
}
