import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  AppShell,
  Button,
  Container,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Space,
  TextInput,
  Title,
} from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { AuthContext, AuthContextType } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';

export function Login() {
  const { t } = useTranslation();
  const { login, isLoggedIn } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [loginfailed, setLoginfailed] = useState(false);

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
      stayLoggedIn: true,
    },
  });

  const loginUser = () => {
    setVisible(true);
    login(form.values.username, form.values.password, form.values.stayLoggedIn)
      .then(() => {
        return navigate('/');
      })
      .catch(() => {
        setLoginfailed(true);
        form.setFieldValue('password', '');
      })
      .finally(() => setVisible(false));
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/');
    }
  });

  return (
    <AppShell
      padding="md"
      styles={(theme) => ({
        main: {
          height: '100vh',
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {/* TODO appshell required? styles duplicated*/}

      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          {t('Login.WelcomeBack')}
        </Title>
        {loginfailed && (
          <>
            <Space h="md" />
            <Alert icon={<AlertCircle size={16} />} color="orange">
              {t('Login.InvalidUsernameOrPassword')}
            </Alert>
          </>
        )}

        <div style={{ position: 'relative' }}>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <LoadingOverlay visible={visible} />

            <form onSubmit={form.onSubmit((values) => loginUser())}>
              <TextInput
                label={t('Login.UserName')}
                placeholder={t('Login.UserName')}
                required
                {...form.getInputProps('username')}
              />
              <PasswordInput
                label="Passwort"
                placeholder="Passwort"
                {...form.getInputProps('password')}
                required
                mt="md"
              />
              {/*
                            <Group position="apart" mt="md">
                                <Checkbox label={t('Login.StayLoggedIn')}
                                          {...form.getInputProps('stayLoggedIn', { type: 'checkbox' })} />

                                <Anchor<'a'> onClick={(event) => event.preventDefault()} href="#" size="sm">
                                    {t('Login.PasswordForgotten')}
                                </Anchor>
                            </Group>
                            */}
              <Button fullWidth mt="xl" type="submit">
                {t('Login.LogIn')}
              </Button>
            </form>
          </Paper>
        </div>
      </Container>
    </AppShell>
  );
}
