import AbstractCRUDService from './AbstractCRUDService';
import axios from '../config/axios';
import IOperation from '../models/IOperation';

class OperationService extends AbstractCRUDService<IOperation> {
  async archive(): Promise<any> {
    return axios.patch(`${this.basePath}/archive`);
  }
}

export default new OperationService('management/operation');
