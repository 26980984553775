import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IDelivery, { getPrefixedDeliveryId } from '../../../../models/IDelivery';
import { StyleSheet, Text } from '@react-pdf/renderer';
import { Button, Grid, Group, Paper, Stack, Title, createStyles } from '@mantine/core';
import moment from 'moment/moment';
import IVehicle from '../../../../models/IVehicle';
import IUser from '../../../../models/IUser';
import ISection from '../../../../models/ISection';
import { IDeliveryPosition } from '../../../../models/IDeliveryPosition';
import { useReactToPrint } from 'react-to-print';
import IPackageUnit from '../../../../models/IPackageUnit';
import IOrderPosition from '../../../../models/IOrder';
import IInquiry from '../../../../models/IInquiry';
import ILocation from '../../../../models/ILocation';
import DeliveryPositionTable from './DeliveryPositionTable';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
});

const useStyles = createStyles(() => ({
  breakPage: {
    pageBreakAfter: "always",
    "&:last-child": {
      pageBreakAfter: "auto",
    }
  }
}))

interface IDeliveryLocation {
  [key: string]: IDeliveryPosition[];
}
function groupedByLocation1(elements: IDeliveryPosition[]) {
  return elements.reduce(
    (result: IDeliveryLocation, currentValue: IDeliveryPosition) => {
      (result[
        (
          (
            (currentValue.ordered_through as IOrderPosition)
              ?.inquired_through as IInquiry
          )?.deliver_to as ILocation
        )?.name
      ] =
        result[
          (
            (
              (currentValue.ordered_through as IOrderPosition)
                ?.inquired_through as IInquiry
            )?.deliver_to as ILocation
          )?.name
        ] || []).push(currentValue);
      return result;
    },
    {},
  );
}

function groupedByLocation(elements: IDeliveryPosition[]): {
  [key: string]: IDeliveryPosition[];
} {
  return elements.reduce(
    (result, currentValue) => {
      const locationName = (
        (
          (currentValue.ordered_through as IOrderPosition)
            ?.inquired_through as IInquiry
        )?.deliver_to as ILocation
      )?.name;

      if (locationName) {
        if (!result[locationName]) {
          result[locationName] = [];
        }
        result[locationName].push(currentValue);
      }

      return result;
    },
    {} as { [key: string]: IDeliveryPosition[] },
  );
}

function createTables(elements: { [key: string]: IDeliveryPosition[] }, t, delivery: IDelivery) {
  return Object.entries(elements).map(([locationName, deliveryPositions]) => {
    const {classes} = useStyles();
    return (
      <Paper withBorder radius="sm" p="xs" m="xs" className={classes.breakPage}>
        <Header delivery={delivery} />
        <Paper mt="xs" mb="xs" withBorder radius="md" p="xs">
          <Grid>
            <Grid.Col xs={2}>
              <Text>{t('main.DeliveryLocation')}:</Text>
            </Grid.Col>
            <Grid.Col xs={8}>
              <Text style={{ fontWeight: 'bold' }}>{locationName}</Text>
            </Grid.Col>
            </Grid>
        </Paper>
        <DeliveryPositionTable deliveryPositions={deliveryPositions} />
      </Paper>
    );
  });
}

function Header(props: {
  delivery?: IDelivery;
}) {
  const {t} = useTranslation();
  return (<Grid>
      <Title order={2} p="xs">{t('DeliveryPrintForm.Title')}</Title>
      <Grid.Col xs={12}>
        <Paper withBorder radius="md" p="xs">
          <Stack>
            <Grid>
              <Grid.Col xs={2}>
                <Text>{t('main.DeliveryNo')}:</Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <Text style={{fontWeight: 'bold'}}>
                  {getPrefixedDeliveryId(props.delivery.id)}
                </Text>
              </Grid.Col>
              <Grid.Col xs={2}>
                <Text>{t('main.ReportingDate')}:</Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <Text style={{fontWeight: 'bold'}}>
                  {moment
                    .utc(props.delivery.created_at)
                    .local()
                    .format('DD.MM.YYYY - HH:mm:ss')}
                </Text>
              </Grid.Col>
              <Grid.Col xs={2}>
                <Text>{t('DeliveryPrintForm.CreatedBy')}:</Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <Text style={{fontWeight: 'bold'}}>
                  {(props.delivery.created_by as IUser)?.name} (
                  {
                    (
                      (props.delivery.created_by as IUser)
                        ?.assigned_to as ISection
                    )?.name
                  }
                  )
                </Text>
              </Grid.Col>
              <Grid.Col xs={2}>
                <Text>{t('DeliveryPrintDialog.PhoneNumber')}:</Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <Text style={{fontWeight: 'bold'}}>
                  {(props.delivery.created_by as IUser)?.phone}
                </Text>
              </Grid.Col>
            </Grid>
          </Stack>
        </Paper>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Paper withBorder radius="md" p="xs">
          <Stack>
            <Grid>
              <Grid.Col xs={2}>
                <Text>{t('main.Vehicle')}:</Text>
              </Grid.Col>
              <Grid.Col xs={4}>
                <Text style={{fontWeight: 'bold'}}>
                  {
                    (props.delivery.delivered_through as IVehicle)
                      ?.radio_call_name
                  }
                  {!(props.delivery.delivered_through as IVehicle)
                    ?.license_plate
                    ? ''
                    : '(' +
                    (props.delivery.delivered_through as IVehicle)
                      ?.license_plate +
                    ')'}
                </Text>
              </Grid.Col>
              <Grid.Col xs={2}>
                <Text>{t('DeliveryPrintDialog.VehicleMaxLoad')}:</Text>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Text style={{fontWeight: 'bold'}}>
                  {
                    (props.delivery.delivered_through as IVehicle)
                      ?.max_cargo
                  }{' '}
                  {
                    (
                      (props.delivery.delivered_through as IVehicle)
                        ?.package_unit as IPackageUnit
                    )?.name
                  }
                  {', '}
                  {
                    (props.delivery.delivered_through as IVehicle)
                      ?.max_weight
                  }{' '}
                  {
                    (props.delivery.delivered_through as IVehicle)
                      ?.weight_unit
                  }
                </Text>
              </Grid.Col>
            </Grid>
          </Stack>
        </Paper>
      </Grid.Col>
    </Grid>)
}

export function DeliveryPrintForm(props: {
  onFinish: Function;
  delivery?: IDelivery;
}) {
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: t('DeliveryPrintForm.PrintDocumentTitle'),
  });

  const locations = groupedByLocation(
    props.delivery.positions as unknown as IDeliveryPosition[],
  );

  return (
    <>
      <div ref={componentRef} style={styles.section}>
        <style type="text/css" media="print">
          {'@page {size: landscape;}'}{' '}
        </style>
        <Grid>
          <Grid.Col>{createTables(locations, t, props.delivery)}</Grid.Col>
        </Grid>
      </div>
      <Grid>
        <Grid.Col>
          <Group>
            <Button color="green" onClick={handlePrint}>
              {t('main.Print')}
            </Button>
            <Button color="gray" onClick={() => props.onFinish()}>
              {t('main.Cancel')}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
}
