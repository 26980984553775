import { Modal } from '@mantine/core';
import { SectionForm } from './SectionForm';
import ISection from '../../models/ISection';
import { useTranslation } from 'react-i18next';

export function SectionEditDialog(props: {
  section: ISection;
  opened: boolean;
  setOpened: Function;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      size="lg"
      title={t('SectionEditDialog.Title')}
    >
      <SectionForm
        section={props.section}
        create={false}
        onFinish={() => props.setOpened(false)}
      />
    </Modal>
  );
}
