import React, { ReactNode, useContext } from 'react';
import {
  Box,
  Button,
  Center,
  ColorScheme,
  Group,
  MultiSelect,
  Paper,
  PasswordInput,
  Progress,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Check, X } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import UserService from '../../services/UserService';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import { ResourceContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import IResource from '../../models/IResource';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import { ROLE_ENUM } from '../../api_enums/ROLE_ENUM';
import { ArchiveConfirmDialog } from './ArchiveConfirmDialog';
import { ThemeToggleButton } from '../settings/ThemeToggleButton';
import { LanguagePicker } from '../settings/LanguagePicker';

function RequireRole(props: { roles: ROLE_ENUM[]; children: ReactNode }) {
  const { hasRight } = useContext(AuthContext) as AuthContextType;
  return hasRight(props.roles) ? <>{props.children}</> : <></>;
}

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text color={meets ? 'teal' : 'red'} mt={5} size="sm">
      <Center inline>
        {meets ? <Check size={14} /> : <X size={14} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: 'Enthält Zahlen' },
  { re: /[a-z]/, label: 'Enthält Kleinbuchstaben' },
  { re: /[A-Z]/, label: 'Enthält Großbuchstaben' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Enthält Sonderzeichen' },
];

function getStrength(password: string, password2: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  if (!(password === password2 && password.length > 0)) {
    multiplier += 1;
  }
  return Math.max(100 - (100 / (requirements.length + 2)) * multiplier, 0);
}

export function UserSettings() {
  const { t } = useTranslation();

  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();

  const resourceCtx = useContext(
    ResourceContext,
  ) as AbstractContextType<IResource>;

  const [value, setValue] = useInputState('');
  const [matchValue, setMatchValue] = useInputState('');

  const [showIndicators, setShowIndicators] = useInputState(
    options.show_indicators,
  );
  const [indicatorResource, setIndicatorResource] = useInputState(
    options.indicator_resource,
  );
  const [indicatorTimespan, setIndicatorTimespan] = useInputState(
    options.indicator_timespan,
  );

  const [colorScheme, setColorScheme] = useInputState(
    options.dark_mode ? 'dark' : 'light',
  );
  const { toggleColorScheme } = useMantineColorScheme();

  const [selectedLanguage, setSelectedLanguage] = useInputState(
    options.language,
  );

  const strength = getStrength(value, matchValue);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  const bars = Array(6)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ bar: { transitionDuration: '0ms' } }}
        value={strength - index * (100 / 6) > 0 ? 100 : 0}
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={6}
      />
    ));

  const finallyHandler = () => {
    form.reset();
    setValue('');
    setMatchValue('');
  };

  const updatePassword = () => {
    UserService.patchPassword({ password: value })
      .then(() => {
        showAppNotifcation(true, t('UserSettings.PasswordChangedSuccessfully'));
      })
      .catch(() =>
        showAppNotifcation(false, t('UserSettings.PasswordCouldNotBeChanged')),
      ) // TODO logging react
      .finally(finallyHandler);
  };

  const updateOptions = () => {
    const opt = {
      ...options,
      show_indicators: showIndicators,
      indicator_resource: indicatorResource,
      indicator_timespan: indicatorTimespan,
      dark_mode: colorScheme === 'dark',
      language: selectedLanguage,
    };
    UserService.saveOptions({ options: opt })
      .then(() => {
        setOptions(opt);
        toggleColorScheme(colorScheme as ColorScheme);
        showAppNotifcation(true, t('main.OptionSaved'));
      })
      .catch(() => showAppNotifcation(false, t('main.OptionCannotChange')));
  };

  const form = useForm({});
  const optionsForm = useForm({
    initialValues: getOptions,
  });

  return (
    <>
      <SimpleGrid cols={2}>
        <Paper shadow="xs" p="md">
          <Stack>
            <Title order={4}>{t('UserSettings.ChangePassword')}</Title>
            <form onSubmit={form.onSubmit(() => updatePassword())}>
              <PasswordInput
                style={{ maxWidth: 300 }}
                value={value}
                onChange={setValue}
                placeholder={t('UserSettings.EnterNewPassword')}
                label={t('UserSettings.EnterNewPassword')}
                required
              />
              <PasswordInput
                style={{ maxWidth: 300 }}
                value={matchValue}
                onChange={setMatchValue}
                placeholder={t('UserSettings.RepeatYourPassword')}
                label={t('UserSettings.RepeatYourPassword')}
                required
              />
              <Group spacing={5} grow mt="xs" mb="md" style={{ maxWidth: 300 }}>
                {bars}
              </Group>
              <PasswordRequirement
                label={t('UserSettings.SixCharacters')}
                meets={value.length > 5}
              />
              {checks}
              <PasswordRequirement
                label={t('UserSettings.BothInputMustMatch')}
                meets={value === matchValue && value.length > 0}
              />
              <p></p>
              <Group>
                <Button color="green" type="submit">
                  {t('UserSettings.ChangePassword')}
                </Button>
              </Group>
            </form>
          </Stack>
        </Paper>
        <Paper shadow="xs" p="md">
          <form onSubmit={optionsForm.onSubmit(() => updateOptions())}>
            <Stack>
              <Title order={4}>{t('UserSettings.Indicators')}</Title>

              <Switch
                color="lime"
                label={t('UserSettings.DisplayKeyFigures')}
                checked={showIndicators}
                onChange={setShowIndicators}
              />
              <MultiSelect
                style={{ maxWidth: 300 }}
                label={t('UserSettings.SelectKeyFigures')}
                placeholder={t('UserSettings.SelectKeyFigures')}
                data={resourceCtx.entities.map((e) => ({
                  value: e.id.toString(),
                  label: e.name,
                }))}
                value={indicatorResource}
                onChange={setIndicatorResource}
              />
              <Select
                style={{ maxWidth: 300 }}
                label={t('UserSettings.SelectTimePeriod')}
                placeholder={t('UserSettings.SelectTimeSpan')}
                data={[
                  { value: '1', label: t('UserSettings.LastHour') },
                  { value: '2', label: t('UserSettings.Last2Hours') },
                  { value: '3', label: t('UserSettings.Last3Hours') },
                  { value: '6', label: t('UserSettings.Last6Hours') },
                  { value: '12', label: t('UserSettings.Last12Hours') },
                  { value: '24', label: t('UserSettings.Last24Hours') },
                ]}
                value={indicatorTimespan.toString()}
                onChange={(e) => setIndicatorTimespan(parseInt(e))}
              />

              <Group>
                <Button color="green" type="submit">
                  {t('main.Save')}
                </Button>
              </Group>
            </Stack>
          </form>
        </Paper>
        <Paper shadow="xs" p="md">
          <form onSubmit={optionsForm.onSubmit(() => updateOptions())}>
            <Stack>
              <Title order={4}>{t('UserSettings.GeneralSetting')}</Title>
              <ThemeToggleButton
                colorScheme={colorScheme}
                setColorScheme={setColorScheme}
              />
              <LanguagePicker
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
              <Group>
                <Button color="green" type="submit">
                  {t('main.Save')}
                </Button>
              </Group>
            </Stack>
          </form>
        </Paper>
        <RequireRole roles={[ROLE_ENUM.MANAGER, ROLE_ENUM.ADMINISTRATOR]}>
          <Paper shadow="xs" p="md">
            <Stack>
              <Title order={4}>{t('UserSettings.OperationArchive')}</Title>
              <Group>
                <ArchiveConfirmDialog />
              </Group>
            </Stack>
          </Paper>
        </RequireRole>
      </SimpleGrid>
    </>
  );
}
