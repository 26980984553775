import 'dayjs/locale/de';
import React, { useContext, useState } from 'react';
import {
  ActionIcon,
  Button,
  Chip,
  Container,
  createStyles,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';
import VehicleTypeService from '../../../services/VehicleTypeService';
import { useTranslation } from 'react-i18next';
import IVehicleType from '../../../models/IVehicleType';
import { VehicleTypeContext } from '../../../context/ContextWrapper';
import { AbstractContextType } from '../../../context/AbstractProvider';
import { VehicleTypeDeleteDialog } from './VehicleTypeDeleteDialog';
import { Pencil } from 'tabler-icons-react';
import { useInputState } from '@mantine/hooks';
import cx from 'clsx';

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: IVehicleType[], loadVehicleType) {
  return elements.map((element) => {
    return (
      <tr key={element.id}>
        <td>{element.name}</td>
        <td>{element.abbreviation}</td>
        <td>{element.description}</td>
        <td>{element.crew}</td>
        <td>
          <Chip.Group>
            {element.has.map((e) => (
              <Chip size="xs" color="blue" checked={true} variant="filled">
                {e}
              </Chip>
            ))}
          </Chip.Group>
        </td>
        <td>
          <Group spacing="xs">
            <ActionIcon
              color="orange"
              size="lg"
              onClick={() => loadVehicleType(element)}
              title="Edit"
            >
              <Pencil />
            </ActionIcon>
            <VehicleTypeDeleteDialog vehicleType={element} />
          </Group>
        </td>
      </tr>
    );
  });
}

export function VehicleTypeForm(props: { onFinish: Function }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [has, setHas] = useState([]);
  const [id, setId] = useState(null);
  const [edit, setEdit] = useInputState(null);
  const [scrolled, setScrolled] = useState(false);

  const ctx = useContext(
    VehicleTypeContext,
  ) as AbstractContextType<IVehicleType>;

  const loadVehicleType = (vehicleType: IVehicleType) => {
    setHas(vehicleType.has);
    setId(vehicleType.id);
    form.setValues(vehicleType);
  };

  const saveOrUpdateVehicleType = () => {
    setVisible(true);
    const finallyHandler = () => {
      setVisible(false);
      form.reset();
    };

    if (form.values.id === 0) {
      VehicleTypeService.create(form.values as IVehicleType)
        .then(() => {
          showAppNotifcation(true, t('VehicleTypeForm.VehicleTypeCreate'));
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('VehicleTypeForm.VehicleTypeCanNotCreate'),
          ),
        ) // TODO logging react
        .finally(finallyHandler);
    } else if (id) {
      VehicleTypeService.patch(id, form.values as IVehicleType)
        .then(() => {
          showAppNotifcation(true, t('VehicleTypeForm.VehicleTypesUpdated'));
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('VehicleTypeForm.VehicleTypesCanNotChange'),
          ),
        ) // TODO logging react
        .finally(finallyHandler);
    } else {
      console.error('update needs a vehicle object');
    }
  };

  const form = useForm({
    initialValues: {
      id: 0,
      name: '',
      abbreviation: '',
      description: '',
      crew: '',
      has: [],
      is_active: true,
    },
    validate: {
      name: (value) =>
        value.length < 1 || null ? t('VehicleTypeForm.SelectName') : null,
    },
  });

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={visible} />
      <Container my="md" size="xl">
        <form onSubmit={form.onSubmit(() => saveOrUpdateVehicleType())}>
          <Grid>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="Lastkraftwagen"
                required
                label={t('VehicleTypeForm.Name')}
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col xs={2}>
              <TextInput
                placeholder="LKW"
                label={t('VehicleTypeForm.Abbreviation')}
                {...form.getInputProps('abbreviation')}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <TextInput
                placeholder="Staffel (0/1/5/6)"
                label={t('VehicleTypeForm.Crew')}
                {...form.getInputProps('crew')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <MultiSelect
                label={t('VehicleTypeForm.Has')}
                data={has}
                placeholder="Kran"
                searchable
                creatable
                getCreateLabel={(query) => `+ ${query} erstellen`}
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  setHas((current) => [...current, item]);
                  return item;
                }}
                {...form.getInputProps('has')}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Textarea
                placeholder={t('VehicleTypeForm.Description')}
                label={t('SectionForm.Description')}
                {...form.getInputProps('description')}
              />
            </Grid.Col>
            <Grid.Col>
              <Group>
                <Button
                  color={form.values.id === 0 ? 'green' : 'orange'}
                  type="submit"
                >
                  {form.values.id === 0
                    ? t('VehicleTypeForm.CreateVehicleType')
                    : t('VehicleTypeForm.SaveVehicleType')}
                </Button>
                <Button color="gray" onClick={() => form.reset()}>
                  {t('main.Cancel')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Container>
      <Divider my="xl" />
      <ScrollArea h={'50vh'}>
        <Table striped verticalSpacing="xs" fontSize="md">
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              <th>{t('VehicleTypeForm.Name')}</th>
              <th>{t('VehicleTypeForm.Abbreviation')}</th>
              <th>{t('VehicleTypeForm.Description')}</th>
              <th>{t('VehicleTypeForm.Crew')}</th>
              <th>{t('VehicleTypeForm.Has')}</th>
              <th>{t('VehicleTypeForm.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {createRows(ctx.entities, loadVehicleType)}

            {ctx.loading &&
              [1, 2].map((id) => {
                return (
                  <tr key={id}>
                    <td>
                      <Skeleton height={12} mt={6} width="20%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="30%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="10%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="20%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="10%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="10%" radius="xl" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {!ctx.loading && ctx.entities.length === 0 && (
          <Text align="center">
            {t('VehicleTypeForm.NoVehicleTypesAvailable')}
          </Text>
        )}
      </ScrollArea>
    </div>
  );
}
