import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Switch,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import IUser from '../../models/IUser';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import UserService from '../../services/UserService';
import ISection from '../../models/ISection';
import { SectionContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { RADIO_CALL_PREFIX_ENUM_VALUES } from '../../api_enums/RADIO_CALL_PREFIX_ENUM';
import { useTranslation } from 'react-i18next';
import { ROLE_ENUM } from '../../api_enums/ROLE_ENUM';
import { useInputState } from '@mantine/hooks';

interface UserFormProps {
  create: boolean;
  onFinish: Function;
  user?: IUser;
  sectionCreate: boolean;
  sectionName?: string;
}

export interface UserFormRef {
  getFormValues: () => IUser;
  validateForm: () => boolean;
  resetForm: () => void;
}

export const UserForm = forwardRef<UserFormRef, UserFormProps>(
  ({ create, onFinish, user, sectionCreate, sectionName = '' }, ref) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [isManger, setIsManger] = useInputState(
      user?.roles.includes(ROLE_ENUM.MANAGER) || false,
    );

    const ctx = useContext(SectionContext) as AbstractContextType<ISection>;

    const finallyHandler = () => {
      setVisible(false);
      form.reset();
      onFinish();
    };

    const saveOrUpdateUser = () => {
      form.values.roles = isManger ? [ROLE_ENUM.MANAGER] : [ROLE_ENUM.USER];
      if (create) {
        if (!form?.values?.radio_call_name_prefix) {
          form.values.radio_call_name_prefix = 'none';
        }
        if (!form?.values?.radio_call_group_prefix) {
          form.values.radio_call_group_prefix = 'none';
        }
        UserService.create(form.values as IUser)
          .then((res: any) => {
            showAppNotifcation(
              true,
              t('UserForm.UserCreatedSuccessfully'),
              'PASSWORT: ' + res.data.pw,
              false,
            );
          })
          .catch(() =>
            showAppNotifcation(false, t('UserForm.UserCouldNotBeCreated')),
          ) // TODO logging react
          .finally(finallyHandler);
      } else if (!create && user) {
        UserService.patch(user.id, form.values as IUser)
          .then(() => {
            showAppNotifcation(true, t('UserForm.UserChangedSuccessfully'));
          })
          .catch(() =>
            showAppNotifcation(false, t('UserForm.UserCouldNotChange')),
          ) // TODO logging react
          .finally(finallyHandler);
      } else {
        console.error('update needs a section object');
      }
    };

    const form = useForm({
      initialValues: {
        id: 0,
        name: user?.name || sectionName,
        roles: isManger ? [ROLE_ENUM.MANAGER] : [ROLE_ENUM.USER] || [],
        forename: user?.forename || '',
        surname: user?.surname || '',
        radio_call_name: user?.radio_call_name || '',
        phone: user?.phone || '',
        is_active: user ? user.is_active : true,
        radio_call_group_prefix: user?.radio_call_group_prefix || '',
        radio_call_group: user?.radio_call_group || '',
        radio_call_name_prefix: user?.radio_call_name_prefix || '',
        assigned_to: (user?.assigned_to as ISection)?.id.toString() || '',
      },
      validate: {
        name: (value) =>
          value.length < 3 || value.length > 10
            ? t('UserForm.NameCharactersLimitation')
            : null,
        roles: (value) =>
          value.length < 1 || null ? t('UserForm.SelectRole') : null,
        assigned_to: (value) =>
          !sectionCreate &&
          (value.length < 1 || null ? t('main.SelectOperationSection') : null),
      },
    });

    useEffect(() => {
      if (sectionName) {
        form.setValues({ name: sectionName });
      }
    }, [sectionName]);

    useImperativeHandle(ref, () => ({
      getFormValues: () => form.values,
      validateForm: () => form.validate().hasErrors === false,
      resetForm: () => form.reset(),
    }));

    return (
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={visible} />
        <Container my="md">
          <form onSubmit={form.onSubmit(() => saveOrUpdateUser())}>
            <Grid>
              <Grid.Col xs={12}>
                <TextInput
                  placeholder={t('UserForm.UserName')}
                  label={t('UserForm.UserName')}
                  required
                  {...form.getInputProps('name')}
                />
              </Grid.Col>
              {!sectionCreate && (
                <Grid.Col xs={12}>
                  <Select
                    label={t('UserTable.MissionSection')}
                    placeholder={t('UserTable.MissionSection')}
                    required
                    data={ctx.entities.map((s) => {
                      return { value: s.id.toString(), label: s.name };
                    })}
                    {...form.getInputProps('assigned_to')}
                  />
                </Grid.Col>
              )}
              <Grid.Col xs={3}>
                <Select
                  label={t('UserTable.RadioCallNamePrefix')}
                  placeholder={t('UserTable.RadioCallNamePrefix')}
                  data={Array.from(RADIO_CALL_PREFIX_ENUM_VALUES).map((k) => {
                    return { value: k[0], label: k[1].label };
                  })}
                  {...form.getInputProps('radio_call_name_prefix')}
                />
              </Grid.Col>
              <Grid.Col xs={9}>
                <TextInput
                  placeholder={t('UserTable.RadioCallName')}
                  label={t('UserTable.RadioCallName')}
                  {...form.getInputProps('radio_call_name')}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <Select
                  label={t('UserTable.RadioCallGroupPrefix')}
                  placeholder={t('UserTable.RadioCallGroupPrefix')}
                  data={Array.from(RADIO_CALL_PREFIX_ENUM_VALUES).map((k) => {
                    return { value: k[0], label: k[1].label };
                  })}
                  {...form.getInputProps('radio_call_group_prefix')}
                />
              </Grid.Col>
              <Grid.Col xs={9}>
                <TextInput
                  placeholder={t('UserTable.RadioCallGroup')}
                  label={t('UserTable.RadioCallGroup')}
                  {...form.getInputProps('radio_call_group')}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Switch
                  color="lime"
                  label={t('UserTable.IsAdministrator')}
                  checked={isManger}
                  onChange={setIsManger}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput
                  placeholder={t('UserTable.FirstName')}
                  label={t('UserTable.FirstName')}
                  {...form.getInputProps('forename')}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput
                  placeholder={t('UserTable.LastName')}
                  label={t('UserTable.LastName')}
                  {...form.getInputProps('surname')}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput
                  placeholder={t('UserTable.PhoneNumber')}
                  label={t('UserTable.PhoneNumber')}
                  {...form.getInputProps('phone')}
                />
              </Grid.Col>
              {!create && (
                <Grid.Col xs={4}>
                  <Switch
                    color="lime"
                    label="Aktiv"
                    {...form.getInputProps('is_active', { type: 'checkbox' })}
                  />
                </Grid.Col>
              )}
              {!sectionCreate && (
                <Grid.Col>
                  <Group>
                    <Button color={create ? 'green' : 'orange'} type="submit">
                      {create
                        ? t('UserForm.CreateUser')
                        : t('UserForm.SaveUser')}
                    </Button>
                    <Button color="gray" onClick={() => onFinish()}>
                      {t('main.Cancel')}
                    </Button>
                  </Group>
                </Grid.Col>
              )}
            </Grid>
          </form>
        </Container>
      </div>
    );
  },
);
