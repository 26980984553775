import IDelivery from './IDelivery';
import IInquiry from './IInquiry';
import IVehicle from './IVehicle';
import IOrderPosition from './IOrder';

export interface IDeliveryPosition {
  id: number;
  priority: string;
  shipped_amount: number;
  received_amount: number;
  belongs_to: IDelivery | string;
  ordered_through: IOrderPosition | string;
  status: string;
  inquired_through: IInquiry | string;
  delivered_through: IVehicle | string;
}

export function getPrefixedDeliveryPositionId(id: number) {
  return 'DP-' + id;
}
