import React, { useState } from 'react';
import { ActionIcon, Modal } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { ResourceForm } from './ResourceForm';
import IResource from '../../models/IResource';
import { useTranslation } from 'react-i18next';

export function ResourceEditDialog(props: { resource: IResource }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('ResourceEditDialog.Title')}
        size="lg"
      >
        <ResourceForm
          create={false}
          resource={props.resource}
          onFinish={() => setOpened(false)}
        />
      </Modal>
      <ActionIcon
        color="orange"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('main.Edit')}
      >
        <Pencil />
      </ActionIcon>
    </>
  );
}
