import Axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;
const axios = Axios.create({
  baseURL: baseURL,
  headers: { 'Content-Type': 'application/json' },
});

axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('jwt')) {
      config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// TODO check if robust solution: format may change ord nested dates (alternative: constructor or parse dates in service classes)
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const parseObjectDates = function (obj) {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    const isDate = value && typeof value === 'string' && dateFormat.test(value);
    if (isDate) {
      obj[key] = new Date(value);
    }
  }
};

axios.interceptors.response.use((res) => {
  if (res.data === null || res.data === undefined) return res;

  if (Array.isArray(res.data)) {
    res.data.forEach((o) => parseObjectDates(o));
  } else {
    parseObjectDates(res.data);
  }
  return res;
});

export default axios;
