import IUser from '../models/IUser';
import AbstractCRUDService from './AbstractCRUDService';
import axios from '../config/axios';
import IOptions from '../models/IOptions';

class UserService extends AbstractCRUDService<IUser> {
  async patchPassword(obj: { password: string }): Promise<any> {
    return axios.patch(`general/user/change/password`, obj);
  }

  async resetPassword(userId: number): Promise<any> {
    return axios.patch(`${this.basePath}/change/password/${userId}`);
  }

  async saveOptions(obj: { options: IOptions }): Promise<any> {
    return axios.patch(`general/user/change/options`, obj);
  }
}

export default new UserService('management/user');
