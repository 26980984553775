import { Modal } from '@mantine/core';
import { SectionForm } from './SectionForm';
import { useTranslation } from 'react-i18next';

export function SectionCreateDialog(props: {
  opened: boolean;
  setOpened: Function;
  supervisor: number | null;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      size="lg"
      title={t('SectionCreateDialog.Title')}
    >
      <SectionForm
        create={true}
        supervisor={props.supervisor}
        onFinish={() => props.setOpened(false)}
      />
    </Modal>
  );
}
