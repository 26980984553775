import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColorScheme, Select } from '@mantine/core';

export function ThemeToggleButton(props: {
  colorScheme: string;
  setColorScheme: any;
}) {
  const { t } = useTranslation();
  const data = [
    { label: t('main.dark'), value: 'dark' },
    { label: t('main.light'), value: 'light' },
  ];

  return (
    <Select
      style={{ maxWidth: 300 }}
      label={t('main.Theme')}
      data={data.map((item) => {
        return { value: item.value.toString(), label: item.label };
      })}
      onChange={(e?: ColorScheme) => {
        props.setColorScheme(e);
      }}
      defaultValue={props.colorScheme}
    ></Select>
  );
}
