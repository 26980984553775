
enum RADIO_CALL_PREFIX_ENUM {
    NONE = 'none',
    FLORIAN = 'florian',
    HEROS = 'heros',
    ROTKREUZ = 'rotkreuz',
    JOHANNES = 'johannes',
    WASSERWACHT = 'wasserwacht',
    PELIKAN = 'pelikan',
    WOLF = 'wolf',
    SPUTNIK = 'sputnik',
    BERGWACHT = 'bergwacht',
    BUNDESWEHR = 'bundeswehr',
    CHRISTOPH='christoph'
}

const RADIO_CALL_PREFIX_ENUM_VALUES = new Map<string, {label: string}>([
    [RADIO_CALL_PREFIX_ENUM.NONE, {label: ' '}],
    [RADIO_CALL_PREFIX_ENUM.FLORIAN, {label: 'Florian'}],
    [RADIO_CALL_PREFIX_ENUM.HEROS, {label: 'Heros'}],
    [RADIO_CALL_PREFIX_ENUM.ROTKREUZ, {label: 'Rotkreuz'}],
    [RADIO_CALL_PREFIX_ENUM.JOHANNES, {label: 'Johannes'}],
    [RADIO_CALL_PREFIX_ENUM.WASSERWACHT, {label: 'Wasserwacht'}],
    [RADIO_CALL_PREFIX_ENUM.PELIKAN, {label: 'Pelikan'}],
    [RADIO_CALL_PREFIX_ENUM.WOLF, {label: 'Wolf'}],
    [RADIO_CALL_PREFIX_ENUM.SPUTNIK, {label: 'Sputnik'}],
    [RADIO_CALL_PREFIX_ENUM.BERGWACHT, {label: 'Bergwacht'}],
    [RADIO_CALL_PREFIX_ENUM.BUNDESWEHR, {label: 'Bundeswehr'}],
    [RADIO_CALL_PREFIX_ENUM.CHRISTOPH, {label: 'Christoph'}]
]);

export {
    RADIO_CALL_PREFIX_ENUM,
    RADIO_CALL_PREFIX_ENUM_VALUES
};