import IInquiry from '../models/IInquiry';
import AbstractCRUDService from './AbstractCRUDService';
import { AxiosResponse } from 'axios';
import axios from '../config/axios';

class InquiryService extends AbstractCRUDService<IInquiry> {
  approvalBasePath = 'approval/inquiry';
  orderBasePath = 'purchasing/order';

  // TODO #16
  override async findAll(): Promise<AxiosResponse<IInquiry[]>> {
    return axios.get<IInquiry[]>(`${this.basePath}/find/all`);
  }

  async approve(objId: number): Promise<AxiosResponse<IInquiry>> {
    return axios.patch(`${this.approvalBasePath}/approve/${objId}`);
  }

  async reject(objId: number): Promise<AxiosResponse<IInquiry>> {
    return axios.patch(`${this.approvalBasePath}/reject/${objId}`);
  }

  async order(
    objIds: number[],
    sectionId: number,
  ): Promise<AxiosResponse<IInquiry>> {
    const obj = {
      inquiry_ids: objIds,
      ordered_from: sectionId,
    };
    return axios.post(`${this.orderBasePath}/order`, obj);
  }
}

export default new InquiryService('inquiring/inquiry');
